import { useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../../../utils/state";

const LoginPageWrapper = styled.div<{ theme: "light" | "dark" }>`
  h5.page-header {
    color: #414dea;
    font-weight: 600;
  }

  a.page-link {
    color: #4508fc;
    text-decoration: underline;
  }

  .page-subtitle {
    font-weight: 500;
  }

  div.text-inputs {
    display: grid;
    justify-items: center;
  }
  div.text-inputs input {
    width: 25%;
    border: 1px solid #000;
    border-radius: 15px;
    padding-left: 10px;
  }

  .page-btn {
    text-transform: none;
    background-image: linear-gradient(#4652ff, #170890);
    width: 15%;
    border-radius: 15px;
    margin-top: 2%;
    margin-bottom: 5%;
  }

  @media only screen and (max-width: 650px) {
    div.text-inputs input {
      width: 75%;
    }

    .page-btn {
      width: 70%;
      margin-bottom: 15%;
      margin-top: 8%;
    }
  }
`;

const LoginPage = () => {
  const { theme } = useContext(AppContext);
  
  return (
    <LoginPageWrapper theme={theme}>
      <h5 className="page-header center">Glad to have you back!</h5>
      <p className="page-subtitle center">
        New to payledger?{" "}
        <a className="page-link" href="/create-account">
          Sign up
        </a>
      </p>
      <div className="text-inputs center">
        <input
          placeholder="Your Email Address"
          type="email"
          className="email-input"
          id="email-input"
        />

        <input
          placeholder="password"
          type="password"
          className="password-input"
          id="password-input"
        />
        <button className="waves-effect waves-light btn page-btn">
          Log In
        </button>
      </div>
    </LoginPageWrapper>
  );
};

export default LoginPage;
