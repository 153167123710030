import { useRef } from "react";
import styled from "styled-components";

const QuestionsSectionWrapper = styled.section<{ theme: "light" | "dark" }>`
  padding-top: 10vh;
  margin-top: -3vh;
  background-color: ${(props) =>
    props.theme === "light" ? "unset" : "#142b7c"};

  h5.heading {
    font-weight: bold;
    color: ${(props) => (props.theme === "light" ? " #100768" : "white")};
  }

  p.subheading {
    font-size: 20px;
    font-weight: 400;
    color: ${(props) => (props.theme === "light" ? "#292D32" : "white")};
    max-width: 55vw;
    margin: 0 auto;
  }

  p.subheading a.subheading-help-center {
    text-decoration: underline;
    color: ${(props) => (props.theme === "light" ? " #100768" : "#FC8908")};
  }

  div.card {
    border-radius: 15px;
    transition: height 2s;
    cursor: pointer;
    background-color: ${(props) =>
      props.theme === "light" ? "unset" : "#113189"};
  }

  div.question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2vw 2vw 0 2vw;
  }

  div.container {
    margin-left: auto !important;
    margin-top: 5vh;
  }

  div.question p {
    font-weight: bold;
    color: ${(props) => (props.theme === "light" ? "#100768" : "#FC8908")};
    max-width: 25vw;
  }

  div.question i {
    font-size: 22px;
  }

  div.question i.fa-plus-square {
    color: ${(props) => (props.theme === "light" ? "#B2ABAB" : "#FC8908")};
  }

  div.question i.fa-minus-square {
    font-size: 22px;
    color: ${(props) => (props.theme === "light" ? "#100768" : "#FC8908")};
    display: none;
  }

  div.answer p {
    margin-top: 0;
    color: ${(props) => (props.theme === "light" ? "#797979" : "white")};
    padding: 0 2vw 0 2vw;
    display: none;
  }

  div.answer {
    padding-bottom: 2vw;
  }

  @media only screen and (max-width: 62em), only screen and (max-width: 650px) {
    p.subheading {
      font-size: 20px;
      font-weight: 400;
      color: ${(props) => (props.theme === "light" ? "#292D32" : "white")};
      max-width: 70vw;
      margin: 0 auto;
    }

    div.question p {
      max-width: 75vw;
    }
  }
`;

const QuestionsSection = ({ theme }: { theme: "light" | "dark" }) => {
  const showing = useRef<any>({});
  const questions1Ref = useRef<any>();
  const questions2Ref = useRef<any>();
  const questions3Ref = useRef<any>();
  const questions4Ref = useRef<any>();
  const questions5Ref = useRef<any>();
  const questions6Ref = useRef<any>();

  const showAnswers = (ref: any, position: number) => {
    ref!.current.firstChild.children[1].style.display = "none";
    ref!.current.firstChild.lastChild.style.display = "block";
    ref!.current.lastChild.firstChild.style.display = "block";
    showing.current[position] = true;
  };

  const hideAnswers = (ref: any, position: number) => {
    ref!.current.firstChild.children[1].style.display = "block";
    ref!.current.firstChild.lastChild.style.display = "none";
    ref!.current.lastChild.firstChild.style.display = "none";
    showing.current[position] = false;
  };

  return (
    <QuestionsSectionWrapper theme={theme} className="questionnaire">
      <h5 className="heading center">Have Questions? We Have Answers.</h5>
      <p className="subheading center">
        Find quick solutions to common queries about our services, features, and
        support. If you don't find what you're looking for, feel free to contact
        our{" "}
        <a
          onClick={(e) => {
            e.preventDefault();
          }}
          className="subheading-help-center"
          href="#!"
        >
          Help Centre
        </a>
      </p>
      <div className="container">
        <div className="row">
          <div className="col m6 l6 s12">
            <div
              className={`card`}
              ref={questions1Ref}
              onClick={() => {
                !showing.current[1]
                  ? showAnswers(questions1Ref, 1)
                  : hideAnswers(questions1Ref, 1);
              }}
            >
              <div className="question">
                <p>Is there customer support available?</p>
                <i className="fa fa-plus-square" aria-hidden="true"></i>
                <i className="fa fa-minus-square" aria-hidden="true"></i>
              </div>
              <div className="answer">
                <p className="animate__animated animate__fadeIn animate__slow">
                  Yes, we provide 24/7 customer support to ensure your queries
                  are addressed promptly. You can reach us via email, live chat,
                  or through the contact form on our website.
                </p>
              </div>
            </div>
          </div>
          <div className="col m6 l6 s12">
            <div
              className={`card`}
              ref={questions2Ref}
              onClick={() => {
                !showing.current[2]
                  ? showAnswers(questions2Ref, 2)
                  : hideAnswers(questions2Ref, 2);
              }}
            >
              <div className="question">
                <p>What is your refund policy?</p>
                <i className="fa fa-plus-square" aria-hidden="true"></i>
                <i className="fa fa-minus-square" aria-hidden="true"></i>
              </div>
              <div className="answer">
                <p className="animate__animated animate__fadeIn animate__slow">
                  Crypto and token transactions are irreversible. Once sent,
                  they cannot be refunded. Always double-check details before
                  confirming a transaction.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col m6 l6 s12">
            <div
              className={`card`}
              ref={questions3Ref}
              onClick={() => {
                !showing.current[3]
                  ? showAnswers(questions3Ref, 3)
                  : hideAnswers(questions3Ref, 3);
              }}
            >
              <div className="question">
                <p>What services do you offer?</p>
                <i className="fa fa-plus-square" aria-hidden="true"></i>
                <i className="fa fa-minus-square" aria-hidden="true"></i>
              </div>
              <div className="answer">
                <p className="animate__animated animate__fadeIn animate__slow">
                  We offer fiat and crypto transactions, multi-currency wallets,
                  and digital payment solutions.
                </p>
              </div>
            </div>
          </div>
          <div className="col m6 l6 s12">
            <div
              className={`card`}
              ref={questions4Ref}
              onClick={() => {
                !showing.current[4]
                  ? showAnswers(questions4Ref, 4)
                  : hideAnswers(questions4Ref, 4);
              }}
            >
              <div className="question">
                <p>Do you offer customization options for your services?</p>
                <i className="fa fa-plus-square" aria-hidden="true"></i>
                <i className="fa fa-minus-square" aria-hidden="true"></i>
              </div>
              <div className="answer">
                <p className="animate__animated animate__fadeIn animate__slow">
                  Yes, we provide customization based on user needs. Contact
                  support for specific requests.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col m6 l6 s12">
            <div
              className={`card`}
              ref={questions5Ref}
              onClick={() => {
                !showing.current[5]
                  ? showAnswers(questions5Ref, 5)
                  : hideAnswers(questions5Ref, 5);
              }}
            >
              <div className="question">
                <p>How can I get started with your platform?</p>
                <i className="fa fa-plus-square" aria-hidden="true"></i>
                <i className="fa fa-minus-square" aria-hidden="true"></i>
              </div>
              <div className="answer">
                <p className="animate__animated animate__fadeIn animate__slow">
                  Sign up, verify your identity, fund your wallet, and
                  start transacting
                </p>
              </div>
            </div>
          </div>
          <div className="col m6 l6 s12">
            <div
              className={`card`}
              ref={questions6Ref}
              onClick={() => {
                !showing.current[6]
                  ? showAnswers(questions6Ref, 6)
                  : hideAnswers(questions6Ref, 6);
              }}
            >
              <div className="question">
                <p>How secure is my personal information on your platform?</p>
                <i className="fa fa-plus-square" aria-hidden="true"></i>
                <i className="fa fa-minus-square" aria-hidden="true"></i>
              </div>
              <div className="answer">
                <p className="animate__animated animate__fadeIn animate__slow">
                  We use encryption, multi-factor authentication, and regular
                  security checks to protect your data
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </QuestionsSectionWrapper>
  );
};

export default QuestionsSection;
