import { useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../../utils/state";
import RevolvingCryptoBanner from "../../Components/RevolvingCryptoHero";
import CallToActionSection from "../../Components/CallToActionSection";
import WorldChart from "../../assets/imgs/world-chart.png";
import RocketMoney from "../../assets/imgs/rocket-money.png";
import InnovationBulb from "../../assets/imgs/innovation.png";
import SecurityLock from "../../assets/imgs/security.png";
import AccessibilityImg from "../../assets/imgs/acess.png";
import UserCenterImg from "../../assets/imgs/user-center.png";
import EfficiencyImg from "../../assets/imgs/efficiency.png";
import BinanceImg from "../../assets/imgs/binance-frame.png";
import BitcoinFrame from "../../assets/imgs/bitcoin-frame.png";
import EthereumFrame from "../../assets/imgs/ethereum-frame.png";
import EuroFrame from "../../assets/imgs/eur-frame.png";
import LiteCoinFrame from "../../assets/imgs/litecoin-frame.png";
import NGNframe from "../../assets/imgs/ngn-frame.png";
import USDFrame from "../../assets/imgs/usd-frame.png";
import USDTFrame from "../../assets/imgs/usdt-frame.png";

const AboutPageWrapper = styled.div<{ theme: "light" | "dark" }>`
  background-color: ${(props) =>
    props.theme === "light" ? "white" : "#042360"};
  margin-top: -5%;
  padding-top: 7%;
  .hero-image {
    width: 25vw;
    margin-left: 5vw;
  }

  .hero-title {
    color: ${(props) => (props.theme === "light" ? "#F9AD05" : "#1A4DD9")};
  }

  .nav-btn {
    border-radius: 50px;
    text-transform: none;
    background-image: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(to left, #4652FF, #170890)"
        : "linear-gradient(to left, #784312, #DE7C21)"};
  }

  section.seamless-digital-finance {
    background: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(to bottom, #E3ECFF, #FFFFFF, #FFFCFC)"
        : "unset"};
    background-color: ${(props) =>
      props.theme === "light" ? "unset" : "#142b7c"};
    padding-top: 5vh;
    padding-bottom: 7vh;
    color: ${(props) => (props.theme === "light" ? "unset" : "white")};
  }

  .seamless-digital-finance .title {
    color: ${(props) => (props.theme === "light" ? "#100768" : "#F9AD05")};
    font-weight: 500;
    margin: 0 auto;
    max-width: 60vw;
    margin-bottom: 10vh;
  }

  .container {
    margin: auto !important;
  }

  section.seamless-digital-finance h4.subtitle {
    color: ${(props) => (props.theme === "light" ? "#100768" : "#F9AD05")};
    font-weight: 600;
  }

  section.seamless-digital-finance p.description {
    color: ${(props) => (props.theme === "light" ? "#000000" : "white")};
    font-size: 18px;
    max-width: 25vw;
  }

  section.seamless-digital-finance img.digital-finance-img {
    width: 50%;
  }

  section.seamless-digital-finance .seamless-btn-container {
    margin-top: 10vh;
  }

  section.drivers {
    padding-top: 10vh;
    margin-bottom: 10vh;
    background-color: ${(props) =>
      props.theme === "light" ? "#ffffff" : "#142b7c"};
  }

  section.drivers h4.title {
    color: ${(props) => (props.theme === "light" ? "#100768" : "#F9AD05")};
    font-weight: 600;
  }

  section.drivers div.subtitle p {
    font-size: 18px;
    max-width: 52vw;
    margin: auto;
    margin-bottom: 5vh;
    color: ${(props) => (props.theme === "light" ? "#000000" : "#FFFFFF")};
  }

  section.drivers div.container {
    margin-left: auto;
  }

  section.drivers div.card {
    padding-right: 3vw;
    padding-bottom: 10px;
    padding-top: 5vh;
    padding-left: 3vw;
    border-radius: 15px;
    background-color: ${(props) =>
      props.theme === "light" ? "#FFFFFF" : "#100768"};
  }

  section.drivers div.card img {
    width: 15%;
    background-color: ${(props) =>
      props.theme === "light" ? "#EAECF0" : "#1A4DD9"};
    border-radius: 50%;
  }

  section.drivers div.small-card img {
    padding: 1vw;
    width: 35%;
  }

  section.drivers div.card h5.title {
    color: ${(props) => (props.theme === "light" ? "#000000" : "#E3ECFF")};
    font-weight: bold;
  }

  section.drivers div.card p.description {
    font-size: 18px;
    color: ${(props) => (props.theme === "light" ? "#292D32" : "#FFFFFF")};
  }

  section.supported-assets {
    padding-top: 10vh;
    margin-top: -10vh;
    background-color: ${(props) =>
      props.theme === "light" ? "#ffffff" : "#142b7c"};
  }

  section.supported-assets h4.title {
    color: ${(props) => (props.theme === "light" ? "#100768" : "#F9AD05")};
    font-weight: 600;
  }

  section.supported-assets div.subtitle p {
    font-size: 18px;
    max-width: 52vw;
    margin: auto;
    margin-bottom: 5vh;
    color: ${(props) => (props.theme === "light" ? "#292D32" : "#FFFFFF")};
  }

  section.supported-assets .container {
    margin-left: auto;
  }

  section.supported-assets img {
    width: 50%;
  }

  section.supported-assets ul.currency-list {
    display: inline-grid;
    grid-template-columns: auto auto auto auto;
  }

  section.supported-assets ul.currency-list div.coin-name {
    font-size: 21px;
    color: ${(props) => (props.theme === "light" ? "#292D32" : "#FFFFFF")};
    margin: 20px 0;
  }

  @media only screen and (max-width: 600px) {
    padding-bottom: 10%;

    section.seamless-digital-finance .title {
      max-width: unset;
      font-size: x-large;
    }

    section.seamless-digital-finance h4.subtitle {
      text-align: center;
      font-size: x-large;
    }

    section.seamless-digital-finance p.description {
      text-align: center;
      max-width: unset;
      font-size: small;
    }

    section.drivers .title {
      font-size: x-large;
    }

    section.drivers div.subtitle p {
      max-width: unset;
      font-size: small;
    }

    section.drivers div.card {
      text-align: center;
    }

    section.drivers div.card img {
      width: 25%;
      padding: 10px;
    }

    section.drivers div.card p.description {
      font-size: 14px;
    }

    section.supported-assets .title {
      font-size: x-large;
    }

    section.supported-assets div.subtitle p {
      max-width: unset;
      font-size: small;
    }

    section.supported-assets ul.currency-list {
      grid-template-columns: auto auto auto;
    }

    section.supported-assets ul.currency-list div.coin-name {
      font-size: 12px;
      margin: 10px 0;
    }

    section.call-to-action-wrapper section {
      padding-bottom: 0;
    }
  }
`;

const AboutPage = () => {
  const { theme } = useContext(AppContext);

  return (
    <AboutPageWrapper theme={theme}>
      <section className="about-us-hero-background">
        <RevolvingCryptoBanner title="About Us" />
      </section>
      <section className="seamless-digital-finance">
        <div className="container">
          <h4 className="title center">
            A Company Built for the Future of Seamless Digital Finance
          </h4>
          <div className="row">
            <div className="col m6 l6 s12 center">
              <img
                src={WorldChart}
                alt="world chart"
                className="digital-finance-img"
              />
            </div>
            <div className="col m6 l6 s12">
              <h4 className="subtitle">Our Mission</h4>
              <p className="description">
                To revolutionize financial transactions by leveraging blockchain
                technology and cutting-edge security, delivering fast,
                cost-effective, and user-friendly solutions. Our goal is to
                simplify payments, enhance transparency, and provide seamless
                global financial access for everyone.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col m6 l6 s12">
              <h4 className="subtitle">Our Vision</h4>
              <p className="description">
                Empowering a borderless financial future where individuals and
                businesses can seamlessly manage both traditional and digital
                assets in one secure, intuitive platform—accessible anytime,
                anywhere.
              </p>
            </div>
            <div className="col m6 l6 s12 center">
              <img
                src={RocketMoney}
                alt="world chart"
                className="digital-finance-img"
              />
            </div>
          </div>
          <div className="seamless-btn-container center">
            <a
              href="/white-paper"
              target="_blank"
              className="btn nav-btn z-depth-0"
            >
              View our white paper {" >"}
            </a>
          </div>
        </div>
      </section>

      <section className="drivers">
        <h4 className="title center">What Drives Us</h4>
        <div className="subtitle center">
          <p>
            Our values define who we are and how we operate. Every decision we
            make is driven by our commitment to trust, innovation, and
            delivering an unparalleled financial experience.
          </p>
        </div>

        <div className="container">
          <div className="row">
            <div className="col m8 l8 s12">
              <div className="card">
                <img src={InnovationBulb} alt="innovation bulb" />
                <h5 className="title">Innovation</h5>
                <p className="description">
                  Constantly evolving to meet the needs of a digital-first world
                  by embracing cutting-edge technology, refining our processes,
                  and anticipating future trends
                </p>
              </div>
            </div>
            <div className="col m4 s12 l4">
              <div className="card  small-card">
                <img src={SecurityLock} alt="security" />
                <h5 className="title">Security</h5>
                <p className="description">
                  Protecting every transaction with cutting-edge encryption
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col m4 l4 s12">
              <div className="card small-card">
                <img src={AccessibilityImg} alt="Accessibility" />
                <h5 className="title">Accessibility</h5>
                <p className="description">
                  Financial empowerment for everyone, everywhere.
                </p>
              </div>
            </div>
            <div className="col m4 l4 s12">
              <div className="card small-card">
                <img src={EfficiencyImg} alt="security" />
                <h5 className="title">Efficiency</h5>
                <p className="description">
                  Faster, smarter, and more transparent transactions.
                </p>
              </div>
            </div>
            <div className="col m4 l4 s12">
              <div className="card small-card small-card">
                <img src={UserCenterImg} alt="User-Centred" />
                <h5 className="title">User-Centred</h5>
                <p className="description">
                  Designed for ease, built for trust.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="supported-assets">
        <h4 className="title center">Supported Currencies & Tokens</h4>
        <div className="subtitle center">
          <p>
            We provide seamless transactions across a variety of fiat and
            digital currencies. Whether you're sending, receiving, or
            exchanging, PayLedger ensures secure and efficient transfers with
            your preferred assets.
          </p>
        </div>
        <div className="center">
          <ul className="currency-list container">
            <li>
              <img src={BinanceImg} alt="Binance" />
              <div className="coin-name center">
                <span>Binance</span>
              </div>
            </li>

            <li>
              <img src={BitcoinFrame} alt="Bitcoin" />
              <div className="coin-name center">
                <span>Bitcoin</span>
              </div>
            </li>
            <li>
              <img src={EthereumFrame} alt="Ethereum" />
              <div className="coin-name center">
                <span>Ethereum</span>
              </div>
            </li>
            <li>
              <img src={EuroFrame} alt="Euro" />
              <div className="coin-name center">
                <span>Euro</span>
              </div>
            </li>
            <li>
              <img src={LiteCoinFrame} alt="Litecoin" />
              <div className="coin-name center">
                <span>Litecoin</span>
              </div>
            </li>
            <li>
              <img src={NGNframe} alt="NGN" />
              <div className="coin-name center">
                <span>NGN</span>
              </div>
            </li>
            <li>
              <img src={USDFrame} alt="USD" />
              <div className="coin-name center">
                <span>USD</span>
              </div>
            </li>
            <li>
              <img src={USDTFrame} alt="USDT" />
              <div className="coin-name center">
                <span>USDT</span>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section className="call-to-action-wrapper">
        <CallToActionSection theme={theme} />
      </section>
    </AboutPageWrapper>
  );
};

export default AboutPage;
