import { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { RoutesType } from "../utils/types";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import LandingPage from "./LandingPage";
import AboutUsPage from "./About-us";
import FeaturesPage from "./Features";
import LoginPage from "./Authentication/LoginPage";
import CreateAccPage from "./Authentication/CreateAccount";
import ContactUsPage from "./Contact-us";

//TODO: once the pages are completed, implement react helmet for the titles
//  - it should be able to happen here - within the routes component

export const routes: RoutesType[] = [
  {
    title: "Contact Us",
    path: "/contact-us",
    element: ContactUsPage,
    position: "navbar",
    auth: true,
  },
  {
    title: "Features",
    path: "/features",
    element: FeaturesPage,
    position: "navbar",
    auth: true,
  },
  {
    title: "Login",
    path: "/login",
    element: LoginPage,
    position: "navbar",
  },
  {
    title: "Create an account",
    path: "/create-account",
    element: CreateAccPage,
    position: "navbar-btn",
  },
  {
    title: "About Us",
    path: "/about-us",
    element: AboutUsPage,
    position: "navbar",
    isExact: true,
  },
  {
    title: "Home",
    path: "/",
    element: LandingPage,
    isExact: true,
  },
];

//put the navbar and footer in the element
export const RouteWrapper = ({ Component }: { Component: any }) => (
  <Component />
);

/**
 * -> Switch between authenticated page and logged in page
 */

export const ProtectedRoute = ({ Component }: { Component: any }) => {
  const [login] = useState(false); //TODO: change this to confirm login status
  //TODO:   when a page is redirected, update the link with _redirect_link=<link it left>
  return login ? <Component /> : <Redirect to="/login" />;
};

const Routes = () => {
  return (
    <div>
      <Navbar />
      {routes.map((route, index) => (
        <Route
          exact={route.isExact}
          key={`${route.path} - ${index}`}
          path={route.path}
        >
          <RouteWrapper Component={route.element} />
        </Route>
      ))}
      <Footer />
    </div>
  );
};

export default Routes;
