import { useContext } from "react";
import { AppContext } from "../utils/state";
import { RevolvingCryptoBannerType } from "../utils/types";
import styled from "styled-components";
import NairaImg from "../assets/imgs/Naira-coin-img.png";
import SolanaImg from "../assets/imgs/solana-coin-img.png";
import BitcoinImg from "../assets/imgs/bitcoin-coin-img.png";
import BinanceImg from "../assets/imgs/binance-coin-img.png";

const Wrapper = styled.div<{ theme: "light" | "dark" }>`
  background: ${(props) =>
    props.theme === "light"
      ? "linear-gradient(to top, #E3ECFF, #FFFFFF, #FFFCFC)"
      : " #042360"};
  padding-bottom: 35vh;
  padding-top: 10vh;
  .hero-title {
    color: ${(props) => (props.theme === "light" ? "#F9AD05" : "#1A4DD9")};
    font-weight: 500;
  }

  ul.revolving-cryptos {
    transform: skewX(50deg) scale(2);
    margin-top: -75vh;
    margin-left: 4vw;
    position: relative;
    height: 400px;
    list-style: none;
    -webkit-transition: all 0.09s ease-in;
    -moz-transition: all 0.09s ease-in;
    -o-transition: all 0.09s ease-in;
    transition: all 0.09s ease-in;
    overflow: hidden;
  }
  ul.revolving-cryptos li {
    text-indent: -9999px;
    display: block;
    position: absolute;
    border: 1px solid
      ${(props) => (props.theme === "light" ? "#e8ecf7" : "#FFFFFF4D")};
  }
  ul.revolving-cryptos li span {
    display: block;
    position: absolute;
    height: 57px;
    width: 60px;
    transform: scale(0.3);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  ul.revolving-cryptos li.solana {
    width: 160px;
    height: 160px;
    -webkit-border-radius: 82px;
    -moz-border-radius: 82px;
    border-radius: 82px;
    top: 240px;
    left: 400px;
    z-index: 98;
  }
  ul.revolving-cryptos li.solana span {
    background: url(${SolanaImg}) no-repeat 0 0;
    top: 118px;
    left: 5px;
  }
  ul.revolving-cryptos li.bitcoin {
    width: 280px;
    height: 280px;
    -webkit-border-radius: 142px;
    -moz-border-radius: 142px;
    border-radius: 142px;
    top: 180px;
    left: 340px;
    z-index: 96;
  }
  ul.revolving-cryptos li.bitcoin span {
    background: url(${BitcoinImg}) no-repeat 0 0;
    top: 0px;
    left: 198px;
    width: 100px;
    height: 100px;
  }

  ul.revolving-cryptos li.binance {
    width: 460px;
    height: 460px;
    -webkit-border-radius: 232px;
    -moz-border-radius: 232px;
    border-radius: 232px;
    top: 90px;
    left: 250px;
    z-index: 93;
  }
  ul.revolving-cryptos li.binance span {
    background: url(${BinanceImg}) no-repeat 0 0;
    top: 7px;
    left: 320px;
  }
  ul.revolving-cryptos li.naira {
    width: 580px;
    height: 580px;
    -webkit-border-radius: 292px;
    -moz-border-radius: 292px;
    border-radius: 292px;
    top: 30px;
    left: 190px;
    z-index: 91;
  }
  ul.revolving-cryptos li.naira span {
    background: url(${NairaImg}) no-repeat 0 0;
    width: 100px;
    height: 100px;
    top: 79px;
    left: 0px;
  }

  /* CSS3 Animations */
  ul.revolving-cryptos li {
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -webkit-animation-name: orbit;

    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -moz-animation-name: orbit;
  }
  ul.revolving-cryptos li.solana {
    -webkit-animation-duration: 8s;
    -moz-animation-duration: 8s;
  }
  ul.revolving-cryptos li.bitcoin {
    -webkit-animation-duration: 20s;
    -moz-animation-duration: 20s;
  }
  ul.revolving-cryptos li.binance {
    -webkit-animation-duration: 70s;
    -moz-animation-duration: 70s;
  }
  ul.revolving-cryptos li.naira {
    -webkit-animation-duration: 120s;
    -moz-animation-duration: 120s;
  }

  @-webkit-keyframes orbit {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-moz-keyframes orbit {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg);
    }
  }

  @media only screen and (max-width: 650px) {
    padding-bottom: 5vh;
    ul.revolving-cryptos {
      transform: skewX(25deg) scale(1);
      margin-top: -29vh;
      margin-left: -80vw;
    }
  }
`;

const RevolvingCryptoBanner: RevolvingCryptoBannerType = ({ title }) => {
  const { theme } = useContext(AppContext);

  return (
    <Wrapper theme={theme}>
      <h3 className="hero-title center">{title}</h3>
      <ul className="revolving-cryptos">
        <li className="solana">
          <span></span>
        </li>

        <li className="bitcoin">
          <span></span>
        </li>

        <li className="binance">
          <span></span>
        </li>

        <li className="naira">
          <span></span>
        </li>
      </ul>
    </Wrapper>
  );
};

export default RevolvingCryptoBanner;
