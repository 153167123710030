import { useCallback, useEffect, useState, useRef, Dispatch, SetStateAction } from 'react';
import { useEventCallback, useEventListener } from 'usehooks-ts';
import { UseLocalStorageOptions, FiatAssetType, UseGetFiatType, UseGetAllFiatsType, CryptoAssetType, UseGetCryptoType } from './types';
import CountryAndCurrency from '@workmate/country-and-currency';
const cryptocurrencies = require("cryptocurrencies");
// import CryptoCurrencyIcons from "cryptocurrency-icons/";

declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface WindowEventMap {
        'local-storage': CustomEvent;
    }
};

const IS_SERVER = typeof window === 'undefined';

export function useLocalStorage<T>(
    key: string,
    initialValue: T | (() => T),
    options: UseLocalStorageOptions<T> = {},
): [T, Dispatch<SetStateAction<T>>, () => void] {
    const { initializeWithValue = true } = options;

    const serializer = useCallback<(value: T) => string>(
        value => {
            if (options.serializer) {
                return options.serializer(value);
            }

            return JSON.stringify(value);
        },
        [options],
    );

    const deserializer = useCallback<(value: string) => T>(
        value => {
            if (options.deserializer) {
                return options.deserializer(value);
            };

            if (value === 'undefined') {
                return undefined as unknown as T;
            };

            const defaultValue =
                initialValue instanceof Function ? initialValue() : initialValue;

            let parsed: unknown;
            try {
                parsed = JSON.parse(value);
            } catch (error) {
                console.error('Error parsing JSON:', error);
                return defaultValue;
            };

            return parsed as T;
        },
        [options, initialValue],
    );

    const readValue = useCallback((): T => {
        const initialValueToUse =
            initialValue instanceof Function ? initialValue() : initialValue;
        if (IS_SERVER) {
            return initialValueToUse;
        };

        try {
            const raw = window.localStorage.getItem(key);
            return raw ? deserializer(raw) : initialValueToUse;
        } catch (error) {
            console.warn(`Error reading localStorage key “${key}”:`, error);
            return initialValueToUse
        }
    }, [initialValue, key, deserializer])

    const [storedValue, setStoredValue] = useState(() => {
        if (initializeWithValue) {
            return readValue();
        };

        return initialValue instanceof Function ? initialValue() : initialValue;
    })

    const setValue: Dispatch<SetStateAction<T>> = useEventCallback(value => {
        if (IS_SERVER) {
            console.warn(
                `Tried setting localStorage key “${key}” even though environment is not a client`,
            );
        };

        try {
            const newValue = value instanceof Function ? value(readValue()) : value;

            window.localStorage.setItem(key, serializer(newValue));

            setStoredValue(newValue);

            window.dispatchEvent(new StorageEvent('local-storage', { key }));
        } catch (error) {
            console.warn(`Error setting localStorage key “${key}”:`, error);
        };
    });

    const removeValue = useEventCallback(() => {
        if (IS_SERVER) {
            console.warn(
                `Tried removing localStorage key “${key}” even though environment is not a client`,
            );
        };

        const defaultValue =
            initialValue instanceof Function ? initialValue() : initialValue;

        window.localStorage.removeItem(key);


        setStoredValue(defaultValue);
        window.dispatchEvent(new StorageEvent('local-storage', { key }));
    });

    useEffect(() => {
        setStoredValue(readValue());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key]);

    const handleStorageChange = useCallback(
        (event: StorageEvent | CustomEvent) => {
            if ((event as StorageEvent).key && (event as StorageEvent).key !== key) {
                return;
            };
            setStoredValue(readValue());
        },
        [key, readValue],
    )

    useEventListener('storage', handleStorageChange);
    useEventListener('local-storage', handleStorageChange);

    return [storedValue, setValue, removeValue];
};

/**
 * get fiat in the world by country name
 * get fiats in bulk by country names passed in an array
 * @returns [CountryType | null, Dispatch<SetStateAction<string>>]
 */
export const useGetFiat: UseGetFiatType = () => {
    const [name, setName] = useState<string | string[]>("");
    const [fiat, setFiat] = useState<FiatAssetType | FiatAssetType[] | null>(null);

    useEffect(() => {
        const setCountry = (name: string | string[]) => {


            if (!name) {
                setFiat(() => null);
                return;
            }

            if (Array.isArray(name)) {
                const cntrys = name.map(n => {
                    const [cntry] = CountryAndCurrency.getCountriesBy("name", n);

                    return {
                        name: cntry.name,
                        capital: cntry.capital,
                        continent: cntry.continent,
                        flag: cntry.flag,
                        iso2: cntry.iso2,
                        iso3: cntry.iso3,
                        dial_code: cntry.dail_code,
                        latitude: cntry.latitude,
                        longitude: cntry.longitude,
                        currency_unicode: cntry.currency.unicode,
                        currency_code: cntry.currency.code,
                        currency_name: cntry.currency.name,
                        currency_symbol: cntry.currency.symbol,
                    }
                });
                

                setFiat(() => cntrys);

            } else {
                const [cntry] = CountryAndCurrency.getCountriesBy("name", name);

                setFiat(prev => ({
                    ...prev,
                    name: cntry.name,
                    capital: cntry.capital,
                    continent: cntry.continent,
                    flag: cntry.flag,
                    iso2: cntry.iso2,
                    iso3: cntry.iso3,
                    dial_code: cntry.dail_code,
                    latitude: cntry.latitude,
                    longitude: cntry.longitude,
                    currency_unicode: cntry.currency.unicode,
                    currency_code: cntry.currency.code,
                    currency_name: cntry.currency.name,
                    currency_symbol: cntry.currency.symbol,
                }));
            }

        };

        setCountry(name);

        return () => { };

    }, [name]);

    return [fiat, setName];
}

/**
 * get all the fiats in the world
 * @returns CountryType[]
 */
export const useGetAllFiats: UseGetAllFiatsType = () => {
    const fiats = useRef<FiatAssetType[]>([]);
    const countries = CountryAndCurrency.getCountries();

    const mappedFiats = countries.map(cntry => ({
        name: cntry.name,
        capital: cntry.capital,
        continent: cntry.continent,
        flag: cntry.flag,
        iso2: cntry.iso2,
        iso3: cntry.iso3,
        dial_code: cntry.dail_code,
        latitude: cntry.latitude,
        longitude: cntry.longitude,
        currency_unicode: cntry.currency.unicode,
        currency_code: cntry.currency.code,
        currency_name: cntry.currency.name,
        currency_symbol: cntry.currency.symbol,
    }));

    fiats.current = mappedFiats;


    return fiats.current;
};


export const useGetCrypto: UseGetCryptoType = () => {
    const [symbol, setSymbol] = useState<string>("");
    const [asset, setAsset] = useState<CryptoAssetType | null>(null);

    useEffect(() => {
        const getAsset = () => {
            if (!symbol) {
                setAsset(() => null);
                return;
            };
            const name = symbol.toLowerCase();
            const fullName = cryptocurrencies[name];
            const currency_code = symbol.toUpperCase();

            if (!fullName) {
                setAsset(() => null);
                return;
            }


            const image = require(`cryptocurrency-icons/32/color/[${name}].png`);



            setAsset(prev => ({
                ...prev,
                name: fullName,
                currency_symbol: currency_code,
                flag: image,
                currency_code
            }));
        };

        getAsset();

        return () => { };
    }, [symbol]);

    return [asset, setSymbol];
};

/**
 * 
 */
export const useGetAllCrypto = () => {

};

export const useGenerateUserWallet = () => {
    //creates a crypto or fiat wallet for the user
};

export const useGetUserWallet = () => {
    //gets the users current wallets from the database
};

export const useGetUserFiats = () => {
    //get all the fiats of a user
};

export const useGetUserCrypto = () => {
    //gets all the users crypto
};

export const useGetOneUserFiatById = () => {
    //gets one of the users fiat by ID
};

export const useGetOneUserFiatByName = () => {
    //gets one of the users fiat by name
};