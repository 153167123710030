import { useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../../../utils/state";

const CreateAccPageWrapper = styled.div<{ theme: "light" | "dark" }>`
  div.container {
    margin: 0 auto !important;
  }
  h3.page-header {
    font-weight: bold;
  }

  span.company-name {
    color: #2c39ef;
  }

  a.page-link {
    color: #4508fc;
    text-decoration: underline;
  }

  .page-subtitle {
    font-weight: 500;
  }

  div.text-inputs input {
    width: 100%;
    border: 1px solid #000;
    border-radius: 15px;
    padding-left: 10px;
  }

  .page-btn {
    text-transform: none;
    background-image: linear-gradient(#4652ff, #170890);
    width: 15%;
    border-radius: 15px;
    margin-top: 2%;
    margin-bottom: 5%;
  }

  @media only screen and (max-width: 650px) {
    div.text-inputs input {
      width: 100%;
    }

    .page-btn {
      width: 70%;
      margin-bottom: 15%;
      margin-top: 8%;
    }
  }
`;

const CreateAccPage = () => {
  const { theme } = useContext(AppContext);

  return (
    <CreateAccPageWrapper theme={theme}>
      <h3 className="page-header center">
        Welcome to <span className="company-name">Payledger!</span>
      </h3>
      <p className="page-subtitle center">
        Already have an account?{" "}
        <a className="page-link" href="/login">
          Login
        </a>
      </p>
      <div className="text-inputs center">
        <div className="container">
          <div className="row">
            <div className="col m4 l4 s12">
              <input
                placeholder="First Name"
                type="text"
                className="first-name-input"
                id="first-name-input"
              />
            </div>
            <div className="col m4 l4 s12">
              <input
                placeholder="Last Name"
                type="text"
                className="last-name-input"
                id="last-name-input"
              />
            </div>
            <div className="col m4 l4 s12">
              <input
                placeholder="Other Name"
                type="text"
                className="other-name-input"
                id="other-name-input"
              />
            </div>
          </div>
          <div className="row">
            <div className="col m4 l4 s12">
              <input
                placeholder="Date of birth"
                type="text"
                className="dob-input"
                id="dob-input"
              />
            </div>
            <div className="col m4 l4 s12">
              <input
                placeholder="Nationality"
                type="text"
                className="nationality-input"
                id="nationality-input"
              />
            </div>
            <div className="col  l4 s12">
              <input
                placeholder="Address"
                type="text"
                className="address-input"
                id="address-input"
              />
            </div>
          </div>
          <div className="row">
            <div className="col m4 l4 s12">
              <input
                placeholder="Phone Number"
                type="number"
                className="phone-input"
                id="phone-input"
              />
            </div>
            <div className="col m4 l4 s12">
              <input
                placeholder="Email"
                type="email"
                className="email-input"
                id="email-input"
              />
            </div>
            <div className="col  l4 s12">
              <input
                placeholder="password"
                type="password"
                className="password-input"
                id="password-input"
              />
            </div>
          </div>
        </div>

        <button className="waves-effect waves-light btn page-btn">
          Register
        </button>
      </div>
    </CreateAccPageWrapper>
  );
};

export default CreateAccPage;
