import { useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../utils/state";

import WhiteAndYellowLogo from "../assets/imgs/white-and-yellow-logo.png";

const FooterWrapper = styled.div<{ theme: "light" | "dark" }>`
  box-shadow: 6px -5px 10px 0px rgba(0, 0, 0, 0.14);
  border-top-left-radius: 120px;
  border-top-right-radius: 120px;
  padding-top: 20vh;
  margin-top: -15vh;

  background-color: ${(props: any) =>
    props.theme === "light" ? "unset" : "#2B3FB8"};
  color: ${(props: any) => (props.theme === "light" ? "#042360" : "white")};
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-image: ${(props) =>
    props.theme === "light"
      ? "linear-gradient(to bottom, #0E2973, #1A4DD9)"
      : "unset"};

  .copyright-text-holder {
    color: ${(props: any) => (props.theme === "light" ? "white" : "white")};
    margin-top: -30vh;
  }

  .copyright-text {
    color: ${(props: any) => (props.theme === "light" ? "white" : "white")};
  }

  ul li {
    padding: 5px 0;
    color: ${(props: any) => (props.theme === "light" ? "white" : "white")};
  }

  ul li a {
    color: ${(props: any) => (props.theme === "light" ? "white" : "white")};
  }

  .brand-logo {
    width: ${(props: any) => (props.theme === "light" ? "50%" : "50%")};
    margin-bottom: ${(props: any) =>
      props.theme === "light" ? "unset" : "15%"};
  }

  div.container {
    margin-left: auto !important;
  }

  div.footer-call-to-action {
    display: grid;
    grid-template-columns: auto auto;
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
  }

  div.footer-call-to-action span {
    font-size: 1.5rem;
  }

  div.footer-call-to-action a.btn {
    text-transform: none;
    width: 50%;
    border-radius: 50px;
    background-color: white;
    color: #100768;
    margin: 0 auto;
  }

  .small-copyright {
    display: none;
  }

  .large-copyright {
    display: block;
  }

  @media screen and (max-width: 650px) {
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
    margin-top: -15vh;
    .social-links-col {
      margin-top: -15vh;
    }
  }

  @media only screen and (max-width: 62em), only screen and (max-width: 650px) {
    margin-top: -15vh;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;

    .brand-logo {
      width: 50%;
      display: block;
      margin: -50px auto 0;
    }

    .social-links-col {
      margin-top: unset;
    }

    .copyright-text-holder {
      margin-top: unset;
      margin-bottom: 30px;
      text-align: center;
    }

    div.footer-call-to-action {
      display: block;
      padding-bottom: 15px;
    }
    div.footer-call-to-action span {
      display: block;
      margin-bottom: 15px;
      text-align: center;
    }

    div.footer-call-to-action a.btn {
      display: block;
      width: 75%;
    }

    .small-copyright {
      display: block;
    }

    .large-copyright {
      display: none;
    }
  }

  @media only screen and (min-width: 481px),
    only screen and (min-width: 601px) only screen and (max-width: 1024px) {
    .brand-logo {
      width: 50%;
    }
    .copyright-text-holder {
      margin-top: unset;
    }

    .social-links-col {
      margin-top: unset;
    }
  }
`;

/**
 * - add react helmet to navbar - name page title according to it
 */

const Footer = () => {
  const { theme } = useContext(AppContext);

  /**
   * create the page according to how it is for large screen
   * create a footer befitting the small screen
   */

  return (
    <FooterWrapper theme={theme}>
      <div className="container">
        <div className="row">
          <div className="col s12 m4 l4">
            <img
              className="brand-logo"
              src={WhiteAndYellowLogo}
              alt="payledger-logo"
            />
            <div className="copyright-text-holder large-copyright">
              <i className="fa fa-copyright" aria-hidden="true"></i>{" "}
              <span className="copyright-text">
                Copyright {new Date().getFullYear()}
              </span>{" "}
              PayLedger Inc.
              <br /> All rights reserved
            </div>
          </div>
          <div className="col s12 m8 l8">
            <div className="row">
              <div className="col s6 m3 l3">
                <h5>Company</h5>
                <ul>
                  <li>
                    <a href="/about-us">About Us</a>
                  </li>
                  <li>
                    <a href="/careers">Careers</a>
                  </li>
                  <li>
                    <a href="/contact-us">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div className="col s6 m3 l3">
                <h5>Products</h5>
                <ul>
                  <li>
                    <a href="#!">Invoice</a>
                  </li>
                  <li>
                    <a href="#!">Transfer Funds</a>
                  </li>
                  <li>
                    <a href="#!">Virtual Account</a>
                  </li>
                  <li>
                    <a href="#!">Regulatory Guide</a>
                  </li>
                </ul>
              </div>

              <div className="col s6 m3 l3">
                <h5>Support</h5>
                <ul>
                  <li>
                    <a href="#!">Help Center</a>
                  </li>
                  <li>
                    <a href="#!">FAQ</a>
                  </li>
                  <li>
                    <a href="#!">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#!">Terms Of service</a>
                  </li>
                </ul>
              </div>
              <div className="col s6 m3 l3">
                <h5>Social</h5>
                <ul>
                  <li>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://X.com/payledger"
                    >
                      Twitter (X)
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://LinkedIn.com/company/payledger"
                    >
                      Linkedin
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://instagram.com/mypayledger"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.facebook.com/payledger"
                    >
                      Facebook
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-text-holder small-copyright">
          <i className="fa fa-copyright" aria-hidden="true"></i>{" "}
          <span className="copyright-text">
            Copyright {new Date().getFullYear()}
          </span>{" "}
          PayLedger Inc.
          <br /> All rights reserved
        </div>
      </div>
      <hr />
      <div className="container">
        <div className="footer-call-to-action">
          <span>Ready to simplify your finances?</span>
          <a href="/sign-up" className="btn">
            Sign Up Now
          </a>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
