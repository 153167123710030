import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { useLocalStorage } from "./utils/hooks";
import ChatHelp from "./Components/ChatHelp";
import Routes from "./Routes";
import AppRoutes from "./Routes/App";
import NotFound from "./Routes/NotFound";
import { AppContext } from "./utils/state";
import "./App.css";

const AppWrapper = styled.div<{
  sidebaropen: string;
  theme: "light" | "dark";
  scale: number;
}>`
  div.routes,
  div.container,
  div.container-fluid {
    transition: margin-left 0.5s;
    margin-left: ${(props) => (props.sidebaropen === "true" ? "250px" : "0")};
  }

  transform: scale(${(props) => props.scale});
  transform-origin: center;
  transition: transform 0.3s ease, padding 0.3s ease;
  padding-left: ${(props) =>
    props.scale === 1 ? 0 : (1 / props.scale) * 20}px;
  padding-right: ${(props) =>
    props.scale === 1 ? 0 : (1 / props.scale) * 20}px;
`;

function App() {
  const [theme, setTheme] = useLocalStorage<"light" | "dark">(
    "payledger-theme",
    "light"
  );
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [scale, setScale] = useState<number>(1);

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (event.ctrlKey) {
        event.preventDefault();
        setScale((prevScale) => {
          const newScale = event.deltaY < 0 ? prevScale + 0.1 : prevScale - 0.1;
          return Math.max(0.5, Math.min(newScale, 5));
        });
      }
    };

    const handleKeyZoom = (event: KeyboardEvent) => {
      if (event.key === "+" || event.key === "=") {
        event.preventDefault();
        setScale((prevScale) => {
          const newScale = prevScale + 0.1;
          return Math.max(0.5, Math.min(newScale, 5));
        });
      } else if (event.key === "-") {
        event.preventDefault();
        setScale((prevScale) => {
          console.log({ prevScale });
          const newScale = prevScale - 0.1;
          return Math.max(0.5, Math.min(newScale, 5));
        });
      }
    };

    window.addEventListener("wheel", handleWheel);
    window.addEventListener("keydown", handleKeyZoom);

    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.addEventListener("keydown", handleKeyZoom);
    };
  }, []);

  return (
    <Router>
    <AppContext.Provider
      value={{ theme, setTheme, sidebarOpen, setSidebarOpen }}
    >
        <AppWrapper
          className="App"
          sidebaropen={sidebarOpen.toString()}
          theme={theme}
          scale={scale}
        >
          <div className="routes">
            <Switch>
              <Route exact path="/app">
                <AppRoutes />
              </Route>
              <Routes />
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
          <ChatHelp />
        </AppWrapper>
    </AppContext.Provider>
    </Router>
  );
}

export default App;

/**
 * there are protected and unprotected routes
 * I want the unprotected routes to display with their nav
 * I want the protected routes to display with their nav
 *
 */
