import { useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../../utils/state";
import RevolvingCryptoBanner from "../../Components/RevolvingCryptoHero";
import CallToActionSection from "../../Components/CallToActionSection";
import MessagesBubble from "../../assets/imgs/messages-bubble.png";
import MessagesBubbleDark from "../../assets/imgs/messages-bubble-dark.png";
import LocationLight from "../../assets/imgs/location-light.png";
import LocationDark from "../../assets/imgs/location-dark.png";
import Call from "../../assets/imgs/call.png";
import CallDark from "../../assets/imgs/call-dark.png";
import QuestionsSection from "../../Components/QuestionsSection";

const ContactPageWrapper = styled.div<{ theme: "light" | "dark" }>`
  background-color: ${(props) =>
    props.theme === "light" ? "white" : "#042360"};
  margin-top: -5%;
  padding-top: 7%;
  .hero-image {
    width: 25vw;
    margin-left: 5vw;
  }

  .hero-title {
    color: ${(props) => (props.theme === "light" ? "#F9AD05" : "#1A4DD9")};
  }

  .nav-btn {
    border-radius: 50px;
    text-transform: none;
    background-image: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(to left, #4652FF, #170890)"
        : "linear-gradient(to left, #784312, #DE7C21)"};
  }

  section.get-in-touch {
    background: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(to bottom, #E3ECFF, #FFFFFF, #FFFCFC)"
        : "unset"};
    background-color: ${(props) =>
      props.theme === "light" ? "unset" : "#142b7c"};
    padding-top: 5vh;
    padding-bottom: 7vh;
    color: ${(props) => (props.theme === "light" ? "unset" : "white")};
  }

  .get-in-touch .title {
    color: ${(props) => (props.theme === "light" ? "#100768" : "#F9AD05")};
    font-weight: 500;
    margin: 0 auto;
    max-width: 60vw;
    margin-bottom: 10vh;
  }

  .container {
    margin: auto !important;
  }

  section.get-in-touch h6.action-title {
    color: ${(props) => (props.theme === "light" ? "#100768" : "white")};
    font-weight: bold;
  }

  section.get-in-touch div.card {
    padding: 15px;
    border-radius: 15px;
    background-color: ${(props) =>
      props.theme === "light" ? "unset" : "#100768"};
  }

  section.get-in-touch div.card span {
    background-color: ${(props) =>
      props.theme === "light" ? "#E3ECFF" : "#FFD577"};
    height: 50px;
    width: 50px;
    border-radius: 50px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
  }

  section.get-in-touch div.card img {
    width: 25px;
    height: 25px;
  }

  section.get-in-touch div.card button {
    background-color: transparent;
    color: ${(props) => (props.theme === "light" ? "#000000" : "white")};
    border: 1px solid
      ${(props) => (props.theme === "light" ? "#11328A" : "#1B4EDA")};
    text-transform: none;
    border-radius: 15px;
  }
  section.get-in-touch p.action-description {
    max-width: 18vw;
  }

  section.drop-a-message {
    background-color: ${(props) =>
      props.theme === "light" ? "unset" : "#142b7c"};
    padding-bottom: 10px;
  }

  section.drop-a-message .title {
    color: ${(props) => (props.theme === "light" ? "#100768" : "#F9AD05")};
    font-weight: bold;
    margin: 0 auto;
    max-width: 60vw;
  }

  section.drop-a-message p.description {
    margin-bottom: 5vh;
    color: ${(props) => (props.theme === "light" ? "#000000" : "#ffffff")};
  }

  section.drop-a-message input,
  section.drop-a-message textarea {
    border: 1px solid
      ${(props) => (props.theme === "light" ? "#B2ABAB" : "#100768")};
    border-radius: 15px;
    padding-left: 20px;
    width: 95%;
    opacity: ${(props) => (props.theme === "light" ? "1" : "0.7")};
  }

  section.drop-a-message input[type="email"] {
    width: 98%;
  }

  section.drop-a-message textarea {
    width: 100%;
    height: 239px;
    padding-top: 5vh;
  }

  section.drop-a-message label {
    color: ${(props) => (props.theme === "light" ? "#272421" : "white")};
  }

  section.drop-a-message button {
    width: 80%;
    color: ${(props) => (props.theme === "light" ? "white" : "white")};
    border: none;
    padding: 2vh;
  }

  section.drop-a-message p.terms-and-condition {
    color: ${(props) => (props.theme === "light" ? "unset" : "white")};
  }

  section.drop-a-message p.terms-and-condition a {
    color: ${(props) => (props.theme === "light" ? "#100768" : "#F9AD05")};
    text-decoration: underline;
  }

  section.questionnaire p.subheading {
    max-width: 61vw;
    padding-bottom: 2vw;
  }

  section.call-to-action section {
    margin-top: -10vh;
  }

  @media only screen and (max-width: 650px) {
    .get-in-touch .title {
      max-width: unset;
      font-size: x-large;
    }

    section.get-in-touch p.action-description {
      max-width: unset;
    }

    section.get-in-touch div.card {
      text-align: center;
    }

    section.drop-a-message .title {
      font-size: x-large;
      max-width: unset;
    }

    section.drop-a-message .other-row {
      margin-top: -20px;
    }

    section.questionnaire p.subheading {
      max-width: unset;
      font-size: small;
    }
  }
`;

const ContactPage = () => {
  const { theme } = useContext(AppContext);

  return (
    <ContactPageWrapper theme={theme}>
      <section className="contact-us-hero-background">
        <RevolvingCryptoBanner title="Contact Us" />
      </section>
      <section className="get-in-touch">
        <div className="container">
          <h4 className="title center">Get in Touch with Us</h4>
          <div className="row">
            <div className="col m4 l4 s12">
              <div className="card">
                <span>
                  {theme === "light" ? (
                    <img src={MessagesBubble} alt="messages" />
                  ) : (
                    <img src={MessagesBubbleDark} alt="messages" />
                  )}
                </span>
                <h6 className="action-title">Email Us</h6>
                <p className="action-description">
                  Need assistance? Reach out to our team, and we’ll get back to
                  you as soon as possible.
                </p>
                <button className="btn contact-action-btn">Copy Email</button>
              </div>
            </div>
            <div className="col m4 l4 s12">
              <div className="card">
                <span>
                  {theme === "light" ? (
                    <img src={Call} alt="Call" />
                  ) : (
                    <img src={CallDark} alt="messages" />
                  )}
                </span>
                <h6 className="action-title">Call Us</h6>
                <p className="action-description">
                  We’re available 24/7 to assist you. Contact us anytime for
                  quick support.
                </p>
                <button className="btn contact-action-btn">
                  Copy phone number
                </button>
              </div>
            </div>
            <div className="col m4 l4 s12">
              <div className="card">
                <span>
                  {theme === "light" ? (
                    <img src={LocationLight} alt="Location" />
                  ) : (
                    <img src={LocationDark} alt="Location" />
                  )}
                </span>
                <h6 className="action-title">Locate Us</h6>
                <p className="action-description">
                  Stop by our office for in-person assistance. We’d love to meet
                  you!
                </p>
                <button className="btn contact-action-btn">
                  Get Directions
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="drop-a-message">
        <div className="container">
          <h4 className="title center">Drop a Message for Us</h4>
          <p className="center description">
            We’ll get back to you within 24 hrs
          </p>
          <div className="row">
            <div className="col m6 l6 s12">
              <label>Name</label>
              <div className="input-wrapper">
                <input placeholder="Your Name" type="text" />
              </div>
            </div>
            <div className="col m6 l6 s12">
              <label>Phone Number</label>
              <div className="input-wrapper">
                <input placeholder="+234" type="number" />
              </div>
            </div>
          </div>

          <div className="row other-row">
            <div className="col m12 l12 s12">
              <label>Email</label>
              <div className="input-wrapper">
                <input placeholder="payledger@email.com" type="email" />
              </div>
            </div>
          </div>

          <div className="row other-row">
            <div className="col m12 l12 s12">
              <label>Message</label>
              <div className="input-wrapper">
                <textarea placeholder="Leave us a message...." />
              </div>
            </div>
            <div />
            <div className="row other-row">
              <p className="terms-and-condition center">
                By sending us a message, you agree to our{" "}
                <a href="/terms-and-conditions">Terms & Conditions</a> and{" "}
                <a href="/privacy-policy">Privacy Policy</a>
              </p>
            </div>
            <div className="row other-row">
              <div className="col m12 l12 s12 center">
                <button className="nav-btn">Send Message</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="questionnaire">
        <QuestionsSection theme={theme} />
      </section>

      <section className="call-to-action">
        <CallToActionSection theme={theme} />
      </section>
    </ContactPageWrapper>
  );
};

export default ContactPage;
