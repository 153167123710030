import { CustomNavLinkType } from "../utils/types";
import { NavLink } from "react-router-dom";

const CustomNavLink: CustomNavLinkType = ({ to, ...props }) => {
  const link: any = to;

  return (
    <NavLink
      exact
      className={`${props.className}`}
      to={link}
      activeClassName="active"
      {...props}
    >
      {props.children}
    </NavLink>
  );
};

export default CustomNavLink;
