import { useContext } from "react";
import styled from "styled-components";
import QuestionaireSection from "../../Components/QuestionsSection";
import CallToActionSection from "../../Components/CallToActionSection";
import { AppContext } from "../../utils/state";
import DarkHeroImg from "../../assets/imgs/hero-image.png";
import LightHeroImg from "../../assets/imgs/light-hero-img.png";
import DarkModeBackGround from "../../assets/imgs/dark-hero-background-image.png";
import CubeImage from "../../assets/imgs/connected_cubes.png";
import AllInOneWalletImg from "../../assets/imgs/all-in-one-wallet.png";
import CryptoPaymentImg from "../../assets/imgs/crypto-payments.png";
import MobileReadyImg from "../../assets/imgs/mobile-ready.png";
import WalletIconLight from "../../assets/imgs/wallet-3.png";
import LockIconLight from "../../assets/imgs/lock-circle.png";
import GlobalIconLight from "../../assets/imgs/global.png";
import AnalyticsLight from "../../assets/imgs/status-up.png";
import WalletIconDark from "../../assets/imgs/wallet-3-dark.png";
import LockIconDark from "../../assets/imgs/lock-circle-dark.png";
import GlobalIconDark from "../../assets/imgs/global-dark.png";
import AnalyticsDark from "../../assets/imgs/status-up-dark.png";
import LandingCarouselBankCards from "../../assets/imgs/landing-carousel-cards.png";
import YellowCheckMark from "../../assets/imgs/yellow-check.png";

const LandingPageWrapper = styled.div<{ theme: "light" | "dark" }>`
  background-color: ${(props) =>
    props.theme === "light" ? "white" : "#042360"};
  margin-top: -5%;
  padding-top: 7%;

  .hero-image {
    width: 25vw;
    margin-left: 5vw;
  }

  .hero-title {
    font-size: 4vw;
    font-weight: bold;
  }

  .hero-summary {
    font-size: 1.8vw;
  }

  .hero-texts {
    color: ${(props) => (props.theme === "light" ? "#100768" : "white")};
    margin-left: 5vw;
  }

  .nav-btn {
    border-radius: 50px;
    text-transform: none;
    background-image: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(to left, #4652FF, #170890)"
        : "linear-gradient(to left, #784312, #DE7C21)"};
  }

  .hero-background {
    background-image: ${(props) =>
      props.theme === "light" ? "unset" : `url(${DarkModeBackGround})`};
    background-repeat: no-repeat;
    display: inline-block;
    background-size: cover;
    background-position: center;
    height: 70vh;
    background: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(to top, #E3ECFF, #FFFFFF, #FFFCFC)"
        : "unset"};
    margin-bottom: ${(props) => (props.theme === "light" ? "-1vh" : "5vh")};
  }

  .hero-image-background {
    background-image: url(${(props) =>
      props.theme === "light" ? CubeImage : "unset"});
    height: 70vh;
    width: 50vw;
    filter: blur(5px);
    margin-left: 7vw;
  }

  a.google-play-btn {
    background-image: url(https://static.whatsapp.net/rsrc.php/v3/yD/r/iURpFV3szzs.png);
    background-size: auto;
    background-repeat: no-repeat;
    display: inline-block;
    height: 60px;
    width: 175px;
    margin-top: 5px;
  }
  a.apple-store-btn img {
    height: 50px;
  }

  section.more-than-payments {
    background: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(to bottom, #E3ECFF, #FFFFFF, #FFFCFC)"
        : "unset"};
    background-color: ${(props) =>
      props.theme === "light" ? "unset" : "#142b7c"};
    padding-top: 20vh;
    padding-bottom: 7vh;
    color: ${(props) => (props.theme === "light" ? "unset" : "white")};
  }

  .more-than-payments .title {
    color: ${(props) => (props.theme === "light" ? "#100768" : "white")};
    font-weight: 500;
    max-width: 35vw;
    margin: 0 auto;
  }

  .more-than-payments .subtitle {
    color: ${(props) => (props.theme === "light" ? "#272421" : "white")};
    max-width: 32vw;
    margin: 0 auto;
    font-weight: 400;
    margin-top: 3vh;
    font-size: 18px;
  }

  section.more-than-payments ul.top-feature-list {
    display: flex;
    justify-content: center;
  }

  section.more-than-payments ul.bottom-feature-list {
    display: flex;
    justify-content: center;
  }

  section.more-than-payments ul.top-feature-list .title {
    color: ${(props) => (props.theme === "light" ? "#000000" : "white")};
    font-weight: bold;
  }

  section.more-than-payments ul.top-feature-list li.card {
    border: 1px solid
      ${(props) => (props.theme === "light" ? "unset" : "#2B3FB8")};
    background-color: ${(props) =>
      props.theme === "light" ? "unset" : "#100768"};
  }

  section.more-than-payments ul.bottom-feature-list li.card {
    border: 1px solid
      ${(props) => (props.theme === "light" ? "unset" : "#2B3FB8")};
    background-color: ${(props) =>
      props.theme === "light" ? "unset" : "#2B3FB8"};
  }

  section.more-than-payments ul.top-feature-list li.card {
    border-radius: 10px;
    max-width: 18vw;
    max-height: 16vw;
    padding: 20px 20px;
    margin: 10px 15px;
  }

  section.more-than-payments ul.bottom-feature-list li.card img {
    max-width: 2vw;
  }

  section.more-than-payments ul.bottom-feature-list li.card {
    border-radius: 10px;
    max-width: 16vw;
    max-height: 11vw;
    padding: 20px 20px;
    margin: 10px 10px;
  }

  section.more-than-payments ul.bottom-feature-list li.card p {
    max-width: 9.5vw;
    font-weight: 600;
    font-size: 13px;
  }

  section.more-than-payments ul.top-feature-list li.card img {
    max-width: 80px;
  }

  section.more-than-payments ul.top-feature-list li.card p {
    max-width: 13vw;
  }

  section.mission-statement {
    background-color: ${(props) =>
      props.theme === "light" ? "unset" : "#142b7c"};
    padding-bottom: 1vh;
    margin-bottom: -4vh;
  }

  div.mission-statement-container {
    margin-left: auto !important;
    width: 85%;
    border-radius: 15px;
    background: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(to bottom, #FFF5DF,#FDFCFCC7)"
        : "#100768"};
  }

  section.mission-statement img.mission-statement-payment-cards {
    margin-left: -2vw;
    margin-top: -5.5vh;
    width: 120%;
  }

  section.mission-statement div.mission-statement-content {
    padding: 2vw;
  }

  section.mission-statement div.mission-statement-content p {
    color: ${(props) => (props.theme === "light" ? " #272421" : "white")};
    font-weight: 400;
    font-size: 20px;
  }

  section.mission-statement div.mission-statement-content .title {
    color: ${(props) => (props.theme === "light" ? "#1249AA" : "#94630E")};
    font-weight: bold;
    font-size: 25px;
  }

  section.mission-statement div.mission-statement-content .nav-btn {
    border-radius: 50px;
    text-transform: none;
    background-image: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(to left, #4652FF, #170890)"
        : "linear-gradient(to left, #784312, #DE7C21)"};
    width: 30%;
    margin-top: 5vh;
  }

  section.service-experience {
    background-color: ${(props) =>
      props.theme === "light" ? "unset" : "#142b7c"};
    padding-top: 5vh;
  }

  section.service-experience h4.title {
    font-weight: bold;
    color: ${(props) => (props.theme === "light" ? " #100768" : "white")};
  }

  section.service-experience div.subtitle p {
    font-size: 20px;
    font-weight: 400;
    color: ${(props) => (props.theme === "light" ? "#292D32" : "white")};
    max-width: 53vw;
    margin: 0 auto;
  }

  section.service-experience li.card {
    border-radius: 15px;
    max-width: 35vw;
    background-color: ${(props) =>
      props.theme === "light" ? "unset" : "#0E2973"};
  }

  section.service-experience li.card div.service-experience-list-content {
    margin-left: -1vw;
  }

  section.service-experience
    li.card
    div.service-experience-list-content
    h5.heading {
    font-size: 1.5rem;
    font-weight: bold;
    color: ${(props) => (props.theme === "light" ? " #100768" : "white")};
  }

  section.service-experience div.container {
    margin-left: auto;
    margin-top: 7vh;
  }

  section.service-experience li.card p.description {
    font-size: 14px;
    color: ${(props) => (props.theme === "light" ? "#292D32" : "white")};
  }

  section.service-experience li.card .row {
    padding: 0 1vw;
  }

  section.service-experience li.card img {
    width: 3vw;
    margin-top: 2vh;
  }

  section.service-experience div.compliance-suite-description {
  }

  section.service-experience div.compliance-suite-description p {
    margin-left: 15px;
    font-size: 18px;
    line-height: 5vh;
    color: ${(props) => (props.theme === "light" ? "unset" : "white")};
  }

  section.service-experience div.compliance-suite-description .nav-btn {
    border-radius: 50px;
    text-transform: none;
    background-image: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(to left, #4652FF, #170890)"
        : "linear-gradient(to left, #784312, #DE7C21)"};
    margin-top: 5vh;
    width: 100%;
  }

  section.service-experience div.compliance-suite-description .learn-more-btn {
    border-radius: 50px;
    text-transform: none;
    background-color: transparent;
    border: ${(props) =>
      props.theme === "light" ? "1px solid #4652FF" : "1px solid #DE7C21"};
    color: ${(props) => (props.theme === "light" ? "#292D32" : "white")};
    margin-top: 5vh;
    width: 70%;
  }

  .landing-get-started .nav-btn {
    width: 15vw;
    margin-top: -30px;
  }

  .landing-get-started {
    height: 20vh;
  }

  @media only screen and (max-width: 425px) {
    .hero-background {
      margin-top: unset !important;
      height: unset !important;
    }

    .hero-image {
      margin-top :${(props) => (props.theme === "light" ? "-75vh" : "-70vh")};
    }

    section.more-than-payments {
      padding-top: 10vh;
    }
  }

  @media only screen and (max-width: 62em), only screen and (max-width: 650px) {
    .hero-background {
      /*margin-top: -70px;*/
    }

    .hero-image-background {
      display: none;
    }
    .hero-image {
      width: 80vw;
      margin-left: 5vw;
      opacity: 0.5;
      margin-top: -75vh;

    }

    .hero-title {
      font-size: 12vw;
      font-weight: bold;
      max-width: unset;
    }

    .hero-summary {
      font-size: large !important;
    }

    .hero-btns {
      text-align: center;
    }

    .hero-btns > a {
      margin-bottom: 15px;
    }

    .more-than-payments .title {
      max-width: unset;
      font-size: x-large !important;
    }

    .more-than-payments .subtitle {
      max-width: unset;
      font-size: small !important;
    }

    section.more-than-payments ul.top-feature-list {
      display: unset;
    }

    section.more-than-payments ul.bottom-feature-list {
      display: inline-grid;
      grid-template-columns: auto auto;
      row-gap: 2vh;
      column-gap: 2vh;
      margin-left: 35px;
      margin-top: -2vh;
    }

    section.more-than-payments ul.top-feature-list li.card {
      border-radius: 10px;
      max-width: 80vw;
      max-height: 70vw;
      padding: 20px 20px;
      margin: 5vh 15vw;
    }

    section.more-than-payments ul.top-feature-list li.card p {
      max-width: unset;
    }

    section.more-than-payments ul.bottom-feature-list li.card {
      border-radius: 10px;
      max-width: 40vw;
      max-height: 70vw;
      padding: 20px 20px;
      margin: unset;
    }

    section.more-than-payments ul.bottom-feature-list li.card img {
      max-width: 8vw;
    }

    section.more-than-payments ul.bottom-feature-list li.card p {
      max-width: unset;
    }

    section.mission-statement div.mission-statement-content .nav-btn {
      width: 50%;
    }

    section.service-experience div.subtitle p {
      max-width: unset;
    }

    section.service-experience li.card img {
      width: 10vw;
    }

    section.service-experience div.compliance-suite-description p {
      line-height: 4vh;
    }

    section.service-experience li.card {
      max-width: unset;
    }

    section.service-experience
      div.compliance-suite-description
      .learn-more-btn {
      margin-top: 2vh;
      width: 100%;
    }

    .hero-texts {
      margin-top: ${(props) => (props.theme === "light" ? "unset" : "unset")};
      background-color: ${(props) =>
        props.theme === "light" ? "#ffffff69" : "#04236094"};
    }

    .hero-image {
      margin-top: ${(props) => (props.theme === "light" ? "-75vh" : "-70vh")};
      opacity: 0.2;
    }

    .landing-get-started .nav-btn {
      width: 50vw;
    }

    .hero-texts-col {
      margin-left: 5vw;
    }

    .top-feature-list .title {
      font-size: unset;
    }

    .mission-statement-content p {
      font-size: small !important;
    }

    .service-experience .title {
      font-size: x-large !important;
    }

    .service-experience p {
      font-size: small !important;
    }

    .service-experience-list-content h5.heading {
      font-size: large !important;
    }
    .service-experience-list-content p.description {
      font-size: small !important;
    }

    .compliance-suite-description {
      font-size: small !important;
    }

    .questionnaire h5.heading {
      font-size: large !important;
    }

    .questionnaire p.subheading {
      font-size: small !important;
    }
  }

  @media only screen and (min-width: 481px) and (min-width: 601px) and (max-width: 1024px) {
    .hero-background {
      margin-top: 70px;
    }

    .hero-image-background {
      display: none;
    }

    .hero-image-col {
      margin-left: 10% !important;
      margin-top: -10% !important;
    }

    .hero-image-col .hero-image {
      margin-top: 10vh;
      opacity: 0.5;
    }

    .hero-texts-col {
      width: 90% !important;
      text-align: center;
      padding-left: 5%;
    }

    .hero-title {
      font-size: 9vw;
      font-weight: bold;
    }

    .hero-summary {
      font-size: 3.5vw;
    }

    .hero-btns {
      text-align: center;
    }

    .hero-btns > a {
      margin-bottom: 15px;
    }
    }

    .hero-texts {
      margin-top: ${(props) => (props.theme === "light" ? "unset" : "unset")};
      background-color: ${(props) =>
        props.theme === "light" ? "#ffffff69" : "#04236094"};
    }

    .hero-image {
      margin-top: ${(props) => (props.theme === "light" ? "-75vh" : "-70vh")};
    }

    .landing-features .title {
      text-align: center;
    }

    .landing-features .col {
      padding: unset;
    }

    .landing-features-img {
      margin-top: unset;
    }

    .landing-features-img img {
      width: 50vw;
      margin-bottom: -65vh;
      margin-left: -10vw;
      opacity: 0.5;
    }

    .landing-features-second-list {
      background-color: ${(props) =>
        props.theme === "light" ? "transparent" : "#04236094"};
    }

    .landing-get-started .nav-btn {
      width: 50vw;
    }
  }

`;

const LandingPage = () => {
  const { theme } = useContext(AppContext);

  return (
    <LandingPageWrapper theme={theme}>
      <div className="container-fluid">
        <div className="row hero-background">
          <div className="col m6 s12 l6 hero-texts-col">
            <div className="hero-texts">
              <h3 className="hero-title">
                Swift and Secure Borderless Transfers.
              </h3>
              <p className="hero-summary">
                Join a global network of individuals, innovators, businesses,
                and professionals, that enjoy instant, secure, borderless
                payments with premium customer support.
              </p>
              <div className="hero-btns">
                <a
                  href="#!"
                  className="apple-store-btn btn transparent z-depth-0"
                >
                  <img
                    src="https://static.whatsapp.net/rsrc.php/yT/r/DRlZi0ybQQP.svg"
                    alt="apple store link"
                  />
                </a>
                <a
                  href="#!"
                  className="google-play-btn btn transparent z-depth-0"
                >
                  <span className="hide">download from google play</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col m6 s12 l6 hero-image-col">
            <div className="hero-image-background"></div>
            {theme === "light" ? (
              <img
                src={LightHeroImg}
                alt="hero-image"
                className="responsive-img hero-image"
              />
            ) : (
              <img
                src={DarkHeroImg}
                alt="hero-image"
                className="responsive-img hero-image"
              />
            )}
          </div>
        </div>
      </div>
      <section className="more-than-payments">
        <h4 className="title center">
          More Than Just Payments: A Complete Solution
        </h4>
        <p className="subtitle center">
          Stay ahead with tools that work for you—whether you’re a creator,
          freelancer, or entrepreneur.
        </p>

        <ul className="top-feature-list">
          <li className="card center">
            <img
              className="img-responsive"
              src={AllInOneWalletImg}
              alt="all in one wallet"
            />
            <h6 className="title">All-in-One-Wallet</h6>
            <p>Access all your accounts in one place.</p>
          </li>
          <li className="card center">
            <img
              className="img-responsive"
              src={CryptoPaymentImg}
              alt="Crypto payments"
            />
            <h6 className="title">Crypto Payments</h6>
            <p>Seamlessly exchange and transact crypto.</p>
          </li>
          <li className="card center">
            <img
              className="img-responsive"
              src={MobileReadyImg}
              alt="Mobile ready"
            />
            <h6 className="title">Mobile Ready</h6>
            <p>Manage payments on the go.</p>
          </li>
        </ul>
        <ul className="bottom-feature-list">
          <li className="card">
            {theme === "light" ? (
              <img src={WalletIconLight} alt="Wallet Icon" />
            ) : (
              <img src={WalletIconDark} alt="Wallet Icon" />
            )}
            <p>Send and receive payments instantly.</p>
          </li>
          <li className="card">
            {theme === "light" ? (
              <img src={LockIconLight} alt="secured Icon" />
            ) : (
              <img src={LockIconDark} alt="secured Icon" />
            )}
            <p>Enjoy bank-grade secure transactions.</p>
          </li>

          <li className="card">
            {theme === "light" ? (
              <img src={GlobalIconLight} alt="Globe Icon" />
            ) : (
              <img src={GlobalIconDark} alt="Globe Icon" />
            )}
            <p>Make payments globally in any currency.</p>
          </li>
          <li className="card">
            {theme === "light" ? (
              <img src={AnalyticsLight} alt="Analytics Icon" />
            ) : (
              <img src={AnalyticsDark} alt="Analytics Icon" />
            )}
            <p>Track your finances with real-time analytics.</p>
          </li>
        </ul>
      </section>

      <section className="mission-statement">
        <div className="container mission-statement-container">
          <div className="row">
            <div className="col m7 s12 l7">
              <div className="mission-statement-content">
                <p>
                  At Payledger, we are revolutionizing cross border payments by
                  offering a fast, secure, and low cost solution that leverages
                  blockchain technology.
                </p>
                <h6 className="title">Our mission</h6>
                <p>
                  To eliminate the pain points of slow processing times, high
                  fees, and poor customer service in international transactions.
                  We aim to simplify the payment process for both individuals
                  and businesses, ensuring seamless transactions across borders.
                  Whether you're a freelancer, business owner, or expatriate,
                  Payledger is here to make your financial transactions smoother
                  and more efficient.
                </p>
                <a href="/about-us" className="btn nav-btn z-depth-0">
                  Learn More {" >"}
                </a>
              </div>
            </div>
            <div className="col m5 s12 l5">
              <img
                className="mission-statement-payment-cards"
                src={LandingCarouselBankCards}
                alt="Payledger payment cards"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="service-experience">
        <h4 className="title center">
          Experience Services Built for Your Needs
        </h4>
        <div className="subtitle center">
          <p>
            Navigate the complexities of regulatory requirements with ease using
            our tailored compliance solutions. Stay aligned, secure, and
            informed at every step.
          </p>
        </div>

        <div className="container">
          <div className="row">
            <div className="col m6 s12">
              <ul>
                <li className="card">
                  <div className="row">
                    <div className="col m2 l2 s2">
                      <img src={YellowCheckMark} alt="Yellow Check Mark" />
                    </div>
                    <div className="col m10 l10 s10 service-experience-list-content">
                      <h5 className="heading">Region-Specific Policies</h5>
                      <p className="description">
                        Access detailed information and tools tailored to your
                        location’s regulatory standards
                      </p>
                    </div>
                  </div>
                </li>
                <li className="card">
                  <div className="row">
                    <div className="col m2 l2 s2">
                      <img src={YellowCheckMark} alt="Yellow Check Mark" />
                    </div>
                    <div className="col m10 l10 s10 service-experience-list-content">
                      <h5 className="heading">
                        Comprehensive Service Insights
                      </h5>
                      <p className="description">
                        Gain clarity on the services available to you and their
                        compliance compatibility
                      </p>
                    </div>
                  </div>
                </li>
                <li className="card">
                  <div className="row">
                    <div className="col m2 l2 s2">
                      <img src={YellowCheckMark} alt="Yellow Check Mark" />
                    </div>
                    <div className="col m10 l10 s10 service-experience-list-content">
                      <h5 className="heading">
                        Policy Updates and Notifications
                      </h5>
                      <p className="description">
                        Stay informed with real-time alerts on new regulatory
                        changes affecting your operations.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col m6 s12">
              <div className="compliance-suite-description">
                <p>
                  Our compliance suite simplifies the process of meeting
                  regulatory standards across regions. From offering tailored
                  policy insights to automated risk management, we ensure your
                  business stays on track without the usual hassle. With
                  real-time updates and proactive guidance, you’ll have the
                  tools you need to navigate even the most complex requirements
                  confidently.
                </p>
                <div className="row">
                  <div className="col m6 l6 s12">
                    <a href="/about-us" className="btn nav-btn z-depth-0">
                      Start Compliance Check
                    </a>
                  </div>
                  <div className="col m6 l6 s12">
                    <a
                      href="/about-us"
                      className="btn learn-more-btn z-depth-0"
                    >
                      Learn More {" >"}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <QuestionaireSection theme={theme} />

      <CallToActionSection theme={theme} />
    </LandingPageWrapper>
  );
};

export default LandingPage;
