import { useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../utils/state";
import CustomNavLink from "./CustomNavLink";
import LightThemeLogo from "../assets/imgs/blue-and-yellow-logo.png";
import DarkThemeLogo from "../assets/imgs/white-and-yellow-logo.png";

import ThemeSwitchRipple from "./ThemeSwitchRipple";

import { routes } from "../Routes";

//TODO: hide the following link when user logs in

const NavbarWrapper = styled.div<{
  theme: "light" | "dark";
  sidebaropen: string;
}>`
  position: relative;
  z-index: 1;
  background-color: ${(props) =>
    props.theme === "light" ? "#FFFFFF" : "#042360"};
  .btn-mobile {
    margin-right: 5vw;
  }

  nav {
    background-color: ${(props) =>
      props.theme === "light" ? "#FFFFFF" : "#2B3FB8"};
  }

  nav.below-nav {
    opacity: 0;
    max-width: 30%;
    border-bottom-right-radius: 100px;
    box-shadow: 6px 5px 4px 0px rgba(0, 0, 0, 0.14);
    margin-left: ${(props) => (props.sidebaropen === "true" ? "220px" : "0")};
  }

  div.nav-brand-logo {
    display: inline-block;
    background-color: ${(props) =>
      props.theme === "light" ? "white" : "#2B3FB8"};
    height: 190px;
    width: 461px;
    border-bottom-right-radius: 200px;
    box-shadow: -8px 8px 5px 0px rgba(0, 0, 0, 0.14);
  }

  nav.top-nav {
    height: 15vh;
    box-shadow: 6px 5px 4px 0px rgba(0, 0, 0, 0.14);
    margin-left: ${(props) => (props.sidebaropen === "true" ? "220px" : "0")};
    transition: margin-left 0.5s;
  }

  .hide-on-sidebar-open {
    display: ${(props) => (props.sidebaropen === "true" ? "none" : "block")};
  }

  ul.med-nav-links li {
    margin: 0 5px;
  }

  a.links {
    color: ${(props) => (props.theme === "light" ? "#100768" : "white")};
  }

  .mobile-sidebar-icon {
    color: ${(props) => (props.theme === "light" ? "#100768" : "white")};
  }

  .med-nav-links {
    margin-right: 5vw;
  }

  nav img.brand-logo {
    height: auto;
    margin: 3vh 6vw;
  }

  .nav-btn {
    border-radius: 50px;
    text-transform: none;
    background-image: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(to right, #4652FF, #170890)"
        : "linear-gradient(#784312, #DE7C21)"};
    width: 150%;
    text-align: center;
    border-radius: 50px;
  }

  .sidenav {
    height: 100vh;
    width: ${(props) => (props.sidebaropen === "true" ? "250px" : "0")};
    /*margin-left: ${(props) =>
      props.sidebaropen === "true" ? "250px" : "0"};*/
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #2b3fb8;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }

  .sidenav li a {
    text-decoration: none;
    color: white;
    display: block;
    transition: 0.3s;
  }

  .sidenav a:hover {
    color: #f1f1f1;
  }

  .sidenav .closebtn {
    color: white;
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  .below-nav-holder div.profile-dropdown {
    margin-top: -4%;
    float: right;
    margin-right: 7%;
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  .below-nav-holder div.profile-dropdown:hover div.profile-dropdown-content {
    display: block;
  }

  .profile-dropdown-content a.log-out {
    color: #d00;
  }

  .profile-dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .profile-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  div.nav-container {
    display: inline-flex;
    justify-content: space-between;
  }

  div.nav-container ul {
    margin-top: 3vh;
  }

  ul.authentication-buttons {
    margin-right: 5vw;
  }

  .alt-nav-btn {
    color: ${(props) => (props.theme === "light" ? "#292d32" : "white")};
    border: 1px solid
      ${(props) => (props.theme === "light" ? "#113189" : "#DE7C21")};
    width: 150%;
    text-align: center;
    border-radius: 50px;
  }

  ul.authentication-buttons li:nth-child(2) {
    padding: 0 3vw;
  }

    .active {
      font-weight: bolder;
    }

  @media screen and (max-width: 650px) {
      nav.top-nav {
        height: 12vh;
      }
  }

  @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px;
    }
    .sidenav li a {
      font-size: 18px;
    }
    .profile-dropdown {
      margin-top: -10% !important;
    }
  }

  @media only screen and (max-width: 992px) {
    nav img.brand-logo {
      max-width: 40.5%;
      margin-left: 5vw;
      left: unset;
      transform: unset;
      width: 45vw;
      margin-left: 2vw;
      margin-top: 1vh;
    }

    .profile-dropdown {
      margin-top: -10% !important;
    }

    .theme-ripple {
      margin-right: 5vw;
    }

    nav.below-nav {
      max-width: 45%;
    }

    .med-login {
      margin-right: 15px;
      font-weight: 600;
    }

     @media only screen and (max-width: 62em),
    only screen and (max-width: 650px) {

          div.nav-brand-logo {
            height: 100px;
            width: 175px;
        }

    }

    @media only screen and (min-width: 481px), only screen and (min-width: 601px), only screen and (max-width: 1024px)  {
      .mobile-sidebar-icon {
      }
      nav img.brand-logo {
        max-width: 40.5%;
        margin-left: 5vw;
        left: unset;
        transform: unset;
        width: 45vw;
        margin-left: 2vw;
        margin-top: 1vh;
      }
      .profile-dropdown {
        margin-top: -10% !important;
      }
  }
`;

/**
 * //TODO: when the user is logged in, use the app routes
 * hide the sidebar button when user is logged in
 */

const Navbar = () => {
  const { theme, sidebarOpen, setSidebarOpen } = useContext(AppContext);

  return (
    <NavbarWrapper
      className="animate__animated animate__fadeInDown"
      theme={theme}
      sidebaropen={sidebarOpen.toString()}
    >
      <ul className="sidenav">
        <a
          href="#!"
          className="closebtn"
          onClick={() => {
            setSidebarOpen(!sidebarOpen);
          }}
        >
          &times;
        </a>
        <li>
          <CustomNavLink
            onClick={() => {
              setSidebarOpen(!sidebarOpen);
            }}
            exact
            className="links"
            to="/"
          >
            Home
          </CustomNavLink>
        </li>
        <li>
          <CustomNavLink
            onClick={() => {
              setSidebarOpen(!sidebarOpen);
            }}
            exact
            className="links"
            to="/about-us"
          >
            About Us
          </CustomNavLink>
        </li>
        <li>
          <CustomNavLink
            onClick={() => {
              setSidebarOpen(!sidebarOpen);
            }}
            exact
            className="links"
            to="/features"
          >
            Features
          </CustomNavLink>
        </li>
        <li>
          <CustomNavLink
            onClick={() => {
              setSidebarOpen(!sidebarOpen);
            }}
            exact
            className="links"
            to="/contact-us"
          >
            Contact Us
          </CustomNavLink>
        </li>
      </ul>
      <nav className="top-nav">
        <div className="nav-wrapper">
          <div className="nav-container">
            <div className="nav-brand-logo">
              {theme === "light" ? (
                <img
                  className="brand-logo"
                  src={LightThemeLogo}
                  alt="payledger-logo"
                />
              ) : (
                <img
                  className="brand-logo"
                  src={DarkThemeLogo}
                  alt="payledger-logo"
                />
              )}
            </div>

            <ul className="med-nav-links hide-on-med-and-down">
              <li>
                <CustomNavLink exact className="links" to="/">
                  Home
                </CustomNavLink>
              </li>
              <li>
                <CustomNavLink exact className="links" to="/about-us">
                  About Us
                </CustomNavLink>
              </li>
              <li>
                <CustomNavLink exact className="links" to="/features">
                  Features
                </CustomNavLink>
              </li>
              <li>
                <CustomNavLink exact className="links" to="/contact-us">
                  Contact Us
                </CustomNavLink>
              </li>
            </ul>

            <ul className="authentication-buttons hide-on-med-and-down">
              <li>
                <ThemeSwitchRipple />
              </li>
              <li>
                <a href="/login" className="alt-nav-btn">
                  Login
                </a>
              </li>
              <li>
                <a href="/create-account" className="nav-btn">
                  Sign Up
                </a>
              </li>
            </ul>
          </div>

          <a
            href="#!"
            className="right mobile-sidebar-icon btn-mobile hide-on-large-only"
            onClick={() => {
              setSidebarOpen(!sidebarOpen);
            }}
          >
            <i className="fa fa-bars"></i>
          </a>

          <a href="#!" className="right theme-ripple hide-on-large-only">
            <ThemeSwitchRipple />
          </a>
        </div>
      </nav>
      <nav className="below-nav"></nav>
    </NavbarWrapper>
  );
};

export default Navbar;
