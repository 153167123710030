import { useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../../utils/state";
import RevolvingCryptoBanner from "../../Components/RevolvingCryptoHero";
import WalletOverView from "../../assets/imgs/wallet-overview.png";
import WalletOverViewDark from "../../assets/imgs/wallet-overview-dark.png";
import CardOverview from "../../assets/imgs/your-cards-frame.png";
import CardOverviewDark from "../../assets/imgs/wallet-overview-dark.png";
import AddCurrencyOverView from "../../assets/imgs/add-currency-frame.png";
import AddCurrencyOverViewDark from "../../assets/imgs/add-currency-frame-dark.png";
import TransactionHistory from "../../assets/imgs/transaction-history-frame.png";
import TransactionHistoryDark from "../../assets/imgs/transaction-history-frame-dark.png";
import ExchangeImg from "../../assets/imgs/exchange-frame.png";
import ExchangeImgDark from "../../assets/imgs/exchange-frame-dark.png";
import AiRecommedations from "../../assets/imgs/all-recommedations.png";
import AiRecommedationsDark from "../../assets/imgs/all-recommedations-dark.png";
import WatchList from "../../assets/imgs/watchlist-frame.png";
import WatchListDark from "../../assets/imgs/watchlist-frame-dark.png";

import CallToActionSection from "../../Components/CallToActionSection";

const FeaturesWrapper = styled.div<{ theme: "light" | "dark" }>`
  .container {
    margin: auto !important;
  }

  .nav-btn {
    border-radius: 50px;
    text-transform: none;
    background-image: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(to left, #4652FF, #170890)"
        : "linear-gradient(to left, #784312, #DE7C21)"};
  }

  section.features-list {
    background: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(to bottom, #E3ECFF, #FFFFFF, #FFFCFC)"
        : "#042360"};
    background-color: ${(props) =>
      props.theme === "light" ? "unset" : "#142b7c"};
    padding-top: 5vh;
    padding-bottom: 7vh;
    color: ${(props) => (props.theme === "light" ? "unset" : "white")};
  }

  section.features-list .title {
    color: ${(props) => (props.theme === "light" ? "#100768" : "#F9AD05")};
    font-weight: 500;
    margin: 0 auto;
    max-width: 60vw;
    margin-bottom: 10vh;
  }

  section.features-list .features-host-wrapper {
    padding-top: 10vh;
  }

  section.features-list .features-host-wrapper img {
    width: 75%;
  }

  section.features-list .features-host-wrapper .feature-title {
    color: ${(props) => (props.theme === "light" ? "#100768" : "#F9AD05")};
    text-transform: uppercase;
  }

  section.features-list .features-host-wrapper .feature-subtitle {
    font-weight: bold;
    color: ${(props) => (props.theme === "light" ? "#000000" : "#ffffff")};
    font-size: 1.2rem;
    max-width: 20vw;
  }

  section.features-list .features-host-wrapper .description {
    color: ${(props) => (props.theme === "light" ? "#000000" : "#ffffff")};
    padding-right: 8vw;
    padding-bottom: 5vh;
  }

  section.features-list .features-host-wrapper .features-call-to-action {
    color: ${(props) => (props.theme === "light" ? "#100768" : "#F9AD05")};
    font-size: 14px;
    font-weight: 600;
  }

  @media only screen and (max-width: 650px) {
    section.features-list .title {
      max-width: unset;
      font-size: x-large;
    }

    section.features-list .features-host-wrapper .feature-subtitle {
      max-width: unset;
    }

    section.features-list .features-host-wrapper img {
      width: 100%;
      padding-top: 10vh;
    }

    section.call-to-action-section .call-to-action-contents {
        margin-bottom: 7vh;
    }

    section.call-to-action-section section {
      padding-bottom: 0;
    }
  }
`;

const Features = () => {
  const { theme } = useContext(AppContext);

  return (
    <FeaturesWrapper theme={theme}>
      <section className="feature-hero-background">
        <RevolvingCryptoBanner title="Our Features" />
      </section>
      <section className="features-list">
        <div className="container">
          <h4 className="title center">
            Powerful Features for Seamless Transactions
          </h4>
          <div className="row features-host-wrapper">
            <div className="col m6 l6 s12 hide-on-small-only">
              {theme === "light" ? (
                <img src={WalletOverView} alt="wallet Overview" />
              ) : (
                <img src={WalletOverViewDark} alt="wallet Overview" />
              )}
            </div>
            <div className="col m6 l6 s12">
              <p className="feature-title">WALLET OVERVIEW</p>
              <h6 className="feature-subtitle">
                See Your Finances at a Glance
              </h6>
              <p className="description">
                Effortlessly monitor your financial health with a real-time
                overview of your wallet. Track your total balance, spending
                trends, and recent transactions in a clean and intuitive
                dashboard. Get insights that help you make informed financial
                decisions—whether you're budgeting or investing.
              </p>
              <a className="features-call-to-action" href="/app/dashboard">
                Get Started {"   >"}
              </a>
            </div>
            <div className="col m6 l6 s12 show-on-small hide-on-med-and-up">
              {theme === "light" ? (
                <img src={WalletOverView} alt="wallet Overview" />
              ) : (
                <img src={WalletOverViewDark} alt="wallet Overview" />
              )}
            </div>
          </div>

          <div className="row features-host-wrapper">
            <div className="col m6 l6 s12">
              <p className="feature-title">YOUR CARDS</p>
              <h6 className="feature-subtitle">
                Manage Your Linked Cards Securely
              </h6>
              <p className="description">
                Easily add, remove, and manage debit, credit, or virtual cards
                from your account. Get full control over your card transactions,
                set spending limits, and enjoy seamless online and offline
                payments. Your financial security is our top priority.
              </p>
              <a className="features-call-to-action" href="/app/cards">
                Get Started {"   >"}
              </a>
            </div>
            <div className="col m6 l6 s12">
              {theme === "light" ? (
                <img src={CardOverview} alt="Cards Overview" />
              ) : (
                <img src={CardOverviewDark} alt="Cards Overview" />
              )}
            </div>
          </div>

          <div className="row features-host-wrapper">
            <div className="col m6 l6 s12 hide-on-small-only">
              {theme === "light" ? (
                <img src={AddCurrencyOverView} alt="Add Currency Overview" />
              ) : (
                <img
                  src={AddCurrencyOverViewDark}
                  alt="Add Currency Overview"
                />
              )}
            </div>
            <div className="col m6 l6 s12">
              <p className="feature-title">Add Currency</p>
              <h6 className="feature-subtitle">
                Expand Your Wallet with More Currencies
              </h6>
              <p className="description">
                Manage multiple currencies with ease. Whether you need to hold
                fiat or crypto, our platform allows you to seamlessly add new
                currencies in just a few clicks. Stay flexible and transact
                globally without limitations.
              </p>
              <a className="features-call-to-action" href="/app/dashboard">
                Get Started {"   >"}
              </a>
            </div>
            <div className="col m6 l6 s12 show-on-small hide-on-med-and-up">
              {theme === "light" ? (
                <img src={AddCurrencyOverView} alt="Add Currency Overview" />
              ) : (
                <img
                  src={AddCurrencyOverViewDark}
                  alt="Add Currency Overview"
                />
              )}
            </div>
          </div>

          <div className="row features-host-wrapper">
            <div className="col m6 l6 s12">
              <p className="feature-title">Transaction History</p>
              <h6 className="feature-subtitle">
                Track Every Payment with Ease
              </h6>
              <p className="description">
                Stay in control with a detailed breakdown of all your
                transactions. Whether you're making purchases, sending money, or
                receiving payments, you’ll always know where your money goes.
                Use filters to search by date, amount, or status for a
                hassle-free experience.
              </p>
              <a className="features-call-to-action" href="/app/transactions">
                Get Started {"   >"}
              </a>
            </div>
            <div className="col m6 l6 s12">
              {theme === "light" ? (
                <img
                  src={TransactionHistory}
                  alt="Transaction History Overview"
                />
              ) : (
                <img
                  src={TransactionHistoryDark}
                  alt="Transaction History Overview"
                />
              )}
            </div>
          </div>

          <div className="row features-host-wrapper">
            <div className="col m6 l6 s12 hide-on-small-only">
              {theme === "light" ? (
                <img src={ExchangeImg} alt="Exchange Overview" />
              ) : (
                <img src={ExchangeImgDark} alt="Exchange Overview" />
              )}
            </div>
            <div className="col m6 l6 s12">
              <p className="feature-title">Exchange</p>
              <h6 className="feature-subtitle">
                Convert Currencies with One Tap
              </h6>
              <p className="description">
                Instantly exchange between fiat and cryptocurrencies at the best
                available rates. With real-time price tracking and low fees, you
                can swap currencies anytime, anywhere—without worrying about
                hidden costs.
              </p>
              <a className="features-call-to-action" href="/app/dashboard">
                Get Started {"   >"}
              </a>
            </div>
            <div className="col m6 l6 s12 show-on-small hide-on-med-and-up">
              {theme === "light" ? (
                <img src={ExchangeImg} alt="Exchange Overview" />
              ) : (
                <img src={ExchangeImgDark} alt="Exchange Overview" />
              )}
            </div>
          </div>

          <div className="row features-host-wrapper">
            <div className="col m6 l6 s12">
              <p className="feature-title">AI Recommendations</p>
              <h6 className="feature-subtitle">
                Smart Insights for Better Decisions
              </h6>
              <p className="description">
                Leverage AI-powered analytics to get personalized
                recommendations on currency exchange, spending habits, and
                investment opportunities. Our intelligent system analyzes market
                trends to help you save money and maximize value in every
                transaction.
              </p>
              <a className="features-call-to-action" href="/app/exchange">
                Get Started {"   >"}
              </a>
            </div>
            <div className="col m6 l6 s12">
              {theme === "light" ? (
                <img src={AiRecommedations} alt="AI Recommendations" />
              ) : (
                <img src={AiRecommedationsDark} alt="AI Recommendations" />
              )}
            </div>
          </div>

          <div className="row features-host-wrapper">
            <div className="col m6 l6 s12 hide-on-small-only">
              {theme === "light" ? (
                <img src={WatchList} alt="Watchlist Overview" />
              ) : (
                <img src={WatchListDark} alt="Watchlist Overview" />
              )}
            </div>
            <div className="col m6 l6 s12">
              <p className="feature-title">Watchlist</p>
              <h6 className="feature-subtitle">
                Keep an Eye on Your Preferred Currencies
              </h6>
              <p className="description">
                Track the performance of your favorite fiat or crypto assets in
                real-time. Customize your watchlist to monitor market trends,
                get price alerts, and make informed decisions about when to buy,
                sell, or exchange.
              </p>
              <a className="features-call-to-action" href="/app/exchange">
                Get Started {"   >"}
              </a>
            </div>
            <div className="col m6 l6 s12 show-on-small hide-on-med-and-up">
              {theme === "light" ? (
                <img src={WatchList} alt="Watchlist Overview" />
              ) : (
                <img src={WatchListDark} alt="Watchlist Overview" />
              )}
            </div>
          </div>
        </div>
      </section>
    <section className="call-to-action-section">
      <CallToActionSection theme={theme} />
    </section>
    </FeaturesWrapper>
  );
};

export default Features;
