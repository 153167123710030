import { useEffect } from "react";
import styled from "styled-components";
import { useGetFiat, useGetAllFiats, useGetCrypto } from "../../utils/hooks";
const DashboardWrapper = styled.div`
  margin-left: 15px;
  div.btn-links .btn {
    text-transform: none;
    background-color: #303af7;
  }
  div.btn-links a:nth-child(2) {
    margin: 0 15px;
  }
`;

//TODO:
/**
 * the button links
 * the link will all lead to payment and we'll use hashes to open the feature
 */

const Dashboard = () => {
  const [countryInfo, setCountryName] = useGetFiat();
  const countriesInfo = useGetAllFiats();
  const [crypto, setCrypto] = useGetCrypto();

  /**
   * Next steps:
   * create cards for adding currency
   * list currencies on create cards for adding currency
   * 
   * start linking to payments
   */


  // console.log({ countryInfo, countriesInfo, crypto });
  

  useEffect(() => {
    //get the wallet for the user
    setCountryName(["Nigeria", "United States", "United Kingdom", "Germany"]);

    return () => {};
  });

  return (
    <DashboardWrapper>
      <div className="btn-links">
        <a href="/app/payment#send" className="btn">
          Send
        </a>
        <a href="/app/payment#receive" className="btn">
          Receive
        </a>
        <a href="/app/payment#invoice" className="btn">
          Invoice
        </a>
      </div>
      <div className="main"></div>
    </DashboardWrapper>
  );
};

export default Dashboard;
