import { useContext, useState } from "react";
import styled from "styled-components";
import { AppContext } from "../utils/state";

const ThemeSwitchRippleWrapper = styled.div<{ theme: "light" | "dark" }>`
  display: inline;

  button {
    background-image: ${(props) =>
      props.theme === "light"
        ? "linear-gradient(#4652FF, #170890)"
        : "linear-gradient(#784312, #DE7C21)"};
  }

  button i {
    color: white;
  }

  .dot {
    position: absolute;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    background: #100768;
    top: calc(50% - 10px);
    cursor: pointer;
    transform: scale(1);
    transition: all 0.75s ease-out;
  }

  .anim {
    transform: scale(150);
  }

  @media only screen and (min-width: 601px) {
    button i {
      line-height: unset !important;
    }
  }
`;

/**
 * bug-
 * -> the dot doesnt close up when the theme is dark.
 * _> why -> it easy, for white, it should be normal, for dark, it shoyld be the
 * ->
 */

const ThemeSwitchRipple = () => {
  const { theme, setTheme } = useContext(AppContext);
  const [display, setDisplay] = useState<boolean>(false);

  return (
    <ThemeSwitchRippleWrapper theme={theme}>
      <button
        onClick={() => {
          if (!display) {
            setDisplay(!display);
          }
          setTimeout(() => {
            if (theme === "light") {
              setTheme("dark");
            } else {
              setTheme("light");
            }
          }, 1000);
        }}
        className="btn-floating waves-effect waves-light"
      >
        {theme === "light" ? (
          <i className="animate__animated animate__fadeIn fa fa-moon-o"></i>
        ) : (
          <i className="animate__animated animate__fadeIn animate__delay-2s fa fa-sun-o"></i>
        )}
      </button>
      {display ? (
        <div
          className={`dot ${theme !== "light" ? "anim" : ""}`}
          onTransitionEnd={() => setDisplay(!display)}
        ></div>
      ) : (
        <></>
      )}
    </ThemeSwitchRippleWrapper>
  );
};

export default ThemeSwitchRipple;
