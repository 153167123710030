import { Route } from "react-router-dom";
import styled from "styled-components";
import { AppRouteType } from "../../utils/types";
import AppSideBar from "../../Components/AppSidebar";
import { RouteWrapper } from "../index";
import Dashboard from "../Dashboard";

export const appRoutes: AppRouteType[] = [
  {
    title: "Dashboard",
    path: "/",
    element: Dashboard,
    isExact: true,
    position: "navbar",
  },
  {
    title: "Cards",
    path: "/cards",
    element: Dashboard,
    isExact: true,
    position: "navbar",
  },
  {
    title: "Recipients",
    path: "/recipients",
    element: Dashboard,
    isExact: true,
    position: "navbar",
  },
  {
    title: "Payment History",
    path: "/payment#history",
    element: Dashboard,
    isExact: true,
    position: "navbar",
  },
  {
    title: "Crypto Exchange",
    path: "/crypto-exchange",
    element: Dashboard,
    isExact: true,
    position: "navbar",
  },
  {
    title: "Account Verification",
    path: "/account-verification",
    element: Dashboard,
    isExact: true,
    position: "navbar",
  },
];

const AppWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  height: 100vh;

  .app-main-page {
    margin-left: 15px;
    margin-top: 30px;
  }
`;

const Application = () => {
  return (
    <AppWrapper>
      <div className="app-main-page">
        {appRoutes
          .filter((item) => item.position === "navbar")
          .map((route, index) => (
            <Route
              exact={route.isExact}
              key={`app-${route.path}-${index}`}
              path={`/app${route.path}`}
            >
              <RouteWrapper Component={route.element} />
            </Route>
          ))}
      </div>
    </AppWrapper>
  );
};

export default Application;

/**
 * 
TASK
- do sidebar
    - its two 
    - app and landing page
    - look at the two that influences that
- refactor landing page
- do dashboard 
- do add currency
- login
- signup page
- transaction history
-cards
- account verification
- profile
- recipient
- do rest
 */
