import Portal from "./ReactPortal";
import { useState } from "react";
import styled from "styled-components";
import ChatImage from "../assets/imgs/customer-support-chat.png";
import Avatar from "../assets/imgs/live-chat-avatar.png";

const ChatHelpWrapper = styled.div<{ fullwidth: string }>`
  position: fixed;

  .chat-help-icon {
    width: 4vw;
    margin-top: 10px;
  }

  div.chat-popup {
    bottom: 15%;
    z-index: 5;
    width: ${(props) => (props.fullwidth === "true" ? "320px" : "0")};
    height: ${(props) => (props.fullwidth === "true" ? "400px" : "0px")};
    background-color: white;
    border: 1px solid #000;
    border-radius: 15px;
  }

  .fixed-action-btn {
    position: fixed;
    right: 100px;
  }

  .chat-popup {
    position: relative;
  }

  .chat-responses {
    width: 300px;
    height: 230px;
    margin: 10px;
  }

  .input-field-holder {
    display: grid;
    grid-template-columns: auto 35px;
    width: 90%;
    margin: 5% 5%;
    border-radius: 50px;
    border: 1px solid #f7f7f8;
    background-color: #0d2a75;
  }

  .input-field-textarea,
  .input-field-textarea:focus:not([readonly]) {
    margin: 0;
    border-bottom: unset;
    color: white;
    box-shadow: unset;
    padding-left: 10px;
  }

  .btn-send {
    margin: 5px 0;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    background-color: #f7f7f8;
    color: #202123ab;
    margin-left: -10px;
    padding-left: l0px;
  }

  .btn-send-icon {
    font-size: 1rem;
    margin-left: -5px;
  }

  .chat-field-toggler {
    height: 90px;
    background-color: #f68627;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .chat-field-header {
    height: 90px;
    background-color: #0d2a75;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 100px;
    border-top-right-radius: 15px;
  }

  .chat-header-text {
    position: absolute;
    margin-top: 30px;
    margin-left: 10px;
  }

  .chat-field-header img {
    width: 75px;
    margin-top: 5px;
    margin-left: 5px;
  }

  .chat-field-header span.chat-header-text {
    color: white;
  }

  .chat-close-btn {
    background-color: #f68627;
  }

  .chat-close-btn {
    height: 30px;
    width: 30px;
    border-radius: 50px;
  }

  button.chat-close-btn i {
    margin-left: -5.5px;
  }

  .btn-floating {
  }

  @media only screen and (max-width: 600px) {
    & {
      left: ${(props) => (props.fullwidth === "true" ? "17vw" : "85vw")};
    }

    .btn-holder {
      float: ${(props) =>
        props.fullwidth === "true" ? "right" : "unset !important"};
    }
  }

  @media only screen and (max-width: 992px) {
    width: ${(props) => (props.fullwidth === "true" ? "310px" : "0")};
    bottom: 25%;
    .chat-help-icon {
      width: 15vw;
    }
  }

  @media only screen and (min-width: 481px) and (min-width: 601px) and (max-width: 1024px) {
    .chat-help-icon {
      width: 7vw;
    }
  }
`;

const ChatHelp = () => {
  const [loading, toggleLoading] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>("");
  const [fullWidth, toggleFullWidth] = useState<boolean>(false);

  console.log({ prompt });

  const editPrompt = (e: any) => {
    const value = e.target.value;
    setPrompt(value);
  };

  const triggerPrompt = () => {
    toggleLoading(!loading);
  };

  const stopPrompt = () => {
    toggleLoading(!loading);
  };

  return (
    <Portal>
      <ChatHelpWrapper
        className="fixed-action-btn"
        fullwidth={fullWidth.toString()}
      >
        <div className="chat-popup">
          <div className="chat-field-toggler">
            <div className="chat-field-header">
              {fullWidth ? (
                <span>
                  <img src={Avatar} alt="avatar" className="img-responsive" />
                  <span className="chat-header-text">Payledger AI</span>
                </span>
              ) : (
                <></>
              )}
              {fullWidth ? (
                <button
                  className="btn btn-flat white-text right btn-small chat-close-btn"
                  onClick={() => {
                    toggleFullWidth(!fullWidth);
                  }}
                >
                  <i className="fa fa-minus" aria-hidden="true"></i>
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
          {fullWidth ? <div className="chat-responses"></div> : <></>}

          {fullWidth ? (
            <div className="input-field-holder">
              <textarea
                placeholder="Enter your message"
                onKeyUp={(e) => {
                  editPrompt(e);
                }}
                className="materialize-textarea input-field-textarea"
              ></textarea>
              <div className="btn-holder">
                {loading ? (
                  <button onClick={() => stopPrompt()} className="btn btn-send">
                    <i
                      className="fa fa-stop btn-send-icon"
                      aria-hidden="true"
                    ></i>
                  </button>
                ) : (
                  <button
                    onClick={() => triggerPrompt()}
                    className="btn btn-send"
                  >
                    <i
                      className="fa fa-arrow-up btn-send-icon"
                      aria-hidden="true"
                    ></i>
                  </button>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="btn-holder right">
          <a
            href="#!"
            onClick={() => {
              toggleFullWidth(!fullWidth);
            }}
            className="btn-floating btn-large transparent btn-large"
          >
            <img className="chat-help-icon" src={ChatImage} alt="chat icon" />
          </a>
        </div>
      </ChatHelpWrapper>
    </Portal>
  );
};

export default ChatHelp;
