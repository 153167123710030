import styled from "styled-components";
import LandingFeaturesImg from "../assets/imgs/landing-features-img.png";

const CallToActionWrapper = styled.section<{ theme: "light" | "dark" }>`
  background-color: ${(props) =>
    props.theme === "light" ? "unset" : "#142b7c"};
  padding-top: 15vh;
  margin-top: -3vh;

  div.container {
    margin-left: auto;
  }

  div.card {
    border-radius: 15px;
    margin: 0 auto;
    max-width: 70vw;
    background-color: ${(props) =>
      props.theme === "light" ? "white" : "#100768"};
  }

  div.call-to-action-texts {
    max-width: 25vw;
  }

  div.call-to-action-contents {
    padding: 5vh 1.5vw;
  }

  h5.title {
    font-size: 1.7rem;
    font-weight: bold;
    color: ${(props) => (props.theme === "light" ? "#100768" : "white")};
  }

  p.call-to-action-description {
    margin-top: 5vh;
    font-size: 17px;
    color: ${(props) => (props.theme === "light" ? "#292D32" : "white")};
  }

  section.call-to-action img {
    width: 20vw;
    margin-left: -1vw;
  }

  div.call-to-action-img-wrapper h5.img-title {
    font-weight: bold;
    font-size: 1.5rem;
    color: ${(props) => (props.theme === "light" ? "#100768" : "#F9AD05")};
  }

  div.call-to-action-img-wrapper p.img-description {
    max-width: 18vw;
    color: ${(props) => (props.theme === "light" ? "#292D32" : "white")};
  }

  div.call-to-action-contents .nav-btn {
    margin-top: -20vh;
  }

  img {
    width: 25vw;
    margin-left: -2vw;
  }

  @media only screen and (max-width: 62em), only screen and (max-width: 650px) {
    padding-bottom: 10vh;
    div.container {
      margin-left: auto;
    }

    div.card {
      border-radius: 15px;
    }

    div.call-to-action-texts {
      max-width: unset;
      text-align: center;
    }

    img {
      width: 75vw;
    }

    div.call-to-action-img-wrapper p.img-description {
      max-width: unset;
    }

    div.call-to-action-contents .nav-btn {
      margin-top: unset;
      margin-left: 2vw;
    }

    div.call-to-action-img-wrapper h5.img-title {
      text-align: center;
    }

    div.call-to-action-img-wrapper h5.img-title {
      text-align: center;
    }

    .call-to-action-text {
      font-size: large;
    }

    .call-to-action-description {
      font-size: small;
    }

    h5.img-title {
      font-size: large;
    }

    
  }
`;

const CallToActionSection = ({ theme }: { theme: "light" | "dark" }) => (
  <CallToActionWrapper theme={theme}>
    <div className="card">
      <div className="call-to-action-contents">
        <div className="container-fluid">
          <div className="row">
            <div className="col m7 l7 s12">
              <div className="call-to-action-texts">
                <h5 className="title">Take Charge of Your Finances Today</h5>
                <p className="call-to-action-description">
                  Discover how PayLedger simplifies expense tracking, invoice
                  management, and financial planning for your
                  business—effortlessly.
                </p>
              </div>
            </div>
            <div className="col m5 l5 s12">
              <div className="call-to-action-img-wrapper">
                <img
                  className="img-responsive"
                  src={LandingFeaturesImg}
                  alt="group using payledger"
                />
                <h5 className="img-title">Smart Financial Tools</h5>
                <p className="center img-description">
                  Automate invoices and track expenses in real-time.
                </p>
              </div>
            </div>
          </div>
        </div>
        <a href="/login" className="btn btn-large nav-btn z-depth-0">
          Get Started Today {"  >"}
        </a>
      </div>
    </div>
  </CallToActionWrapper>
);

export default CallToActionSection;
